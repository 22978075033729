
export class BrandAmount {
    constructor(
        public name: string,
        public logo: any,
        public totalPrice: number,
        public averagePrice: number,
        public count: number,
        public products: ReportProduct[]
    ) {}
}


export class ReportProduct {
    constructor(
        public name: string,
        public image: any
    ) {}
}
