<div class="modal fade bd-example-modal-lg" id="storeModal" tabindex="-1" role="dialog" data-backdrop="static"
     data-keyboard="false" data-target=".bd-example-modal-lg" aria-labelledby="createProductModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{modalTitle}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="createStoreForm" (submit)="submitForm()" novalidate>
            <div class="form-group">
              <label class="input-title" for="name">Name</label>
              <input placeholder="Enter Store Name" formControlName="name" type="text" class="form-control"
                     name="name" required id="name" [class.is-invalid]="name.invalid && name.touched">
              <div *ngIf="name.invalid && name.touched">
                <small *ngIf="!name.value" class="text-danger">
                  Name is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="streetAddress">Street Address</label>
              <input placeholder="Enter Your Street Address" type="text" class="form-control" name="streetAddress"
                     id="streetAddress" required formControlName="streetAddress"
                     [class.is-invalid]="streetAddress.invalid && streetAddress.touched" (change)="getLocationCoords()">
              <div *ngIf="streetAddress.invalid && streetAddress.touched">
                <small *ngIf="!streetAddress.value" class="text-danger">
                  Street Address is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="additionalStreetAddress">Additional Street Address</label>
              <input placeholder="Enter Your Additional Street Address" type="text" class="form-control" name="additionalStreetAddress"
                     id="additionalStreetAddress" formControlName="additionalStreetAddress">
            </div>
            <div class="row">
              <div class="col-sm-4">
                 <div class="form-group">
              <label class="input-title" for="postcode">PostCode</label>
              <input placeholder="Enter Your Post Code" type="text" class="form-control" name="postcode"
                     id="postcode" required formControlName="postcode"
                     [class.is-invalid]="postcode.invalid && postcode.touched" (change)="getLocationCoords()">
              <div *ngIf="postcode.invalid && postcode.touched">
                <small *ngIf="!postcode.value" class="text-danger">
                  PostCode is required
                </small>
              </div>
            </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
              <label class="input-title" for="city">City</label>
              <input placeholder="Enter Your City" type="text" class="form-control" name="city"
                     id="city" required formControlName="city"
                     [class.is-invalid]="city.invalid && city.touched" (change)="getLocationCoords()">
              <div *ngIf="city.invalid && city.touched">
                <small *ngIf="!city.value" class="text-danger">
                  City is required
                </small>
              </div>
            </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
              <label class="input-title" for="country">Country</label>
              <input placeholder="Enter Your Country" type="text" class="form-control" name="country"
                     id="country" required formControlName="country"
                     [class.is-invalid]="country.invalid && country.touched" (change)="getLocationCoords()">
              <div *ngIf="country.invalid && country.touched">
                <small *ngIf="!country.value" class="text-danger">
                  Country is required
                </small>
              </div>
            </div>
              </div>
            </div>
            <div *ngIf="mapIsLoading" class="loader">
              <img class="loading-icon" src="../../../../assets/images/loader.svg" alt="loading icon">
            </div>
            <div [hidden]="!isMapVisible">
              <agm-map [zoom]="14" [latitude]="latitude" [longitude]="longitude">
                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
              </agm-map>
            </div>
            <div class="form-group">
              <label class="input-title" for="phone">Phone</label>
              <input placeholder="Enter Your Phone" type="text" class="form-control" name="phone"
                     id="phone" formControlName="phone">
            </div>
            <div class="form-group">
              <label class="input-title" for="hours">Hours</label>
              <input placeholder="Enter Store's hours e.g 10:00 - 18:00 (Mon - Sat)" type="text" class="form-control" name="hours"
                     id="hours" required formControlName="hours"
                     [class.is-invalid]="hours.invalid && hours.touched">
              <div *ngIf="hours.invalid && hours.touched">
                <small *ngIf="!hours.value" class="text-danger">
                  The Store's Hours are required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="brands">Brands</label>
              <ng-select
                id="brands"
                [items]="brands"
                [multiple]="true"
                [closeOnSelect]="false"
                [searchable]="false"
                bindLabel="brand"
                bindValue="_id"
                placeholder="Select brands"
                formControlName="selectedBrands">

                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">
                    <img class="brand-logo" [src]="item.logo" alt="brand logo">
                    {{item.name}}
                  </span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <img class="brand-logo" alt="brand-logo" [src]="item.logo">
                  {{item.name}}
                </ng-template>
              </ng-select>
              <div *ngIf="selectedBrands.invalid && selectedBrands.touched">
                <small *ngIf="!selectedBrands.value" class="text-danger">
                  The Store's Brands are required
                </small>
              </div>
            </div>

            <button [disabled]="createStoreForm.invalid" class="glosho-button btn"
                    type="submit">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{buttonTitle}}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
