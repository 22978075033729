import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionDecryptionService {

  constructor() { }

  set(key, value) {
    return CryptoJS.AES.encrypt(value, key);
  }

  // The get method is use for decrypt the value.
  get(key, value) {
    const decrypted = CryptoJS.AES.decrypt(value, key);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
