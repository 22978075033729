import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';
import { ContentBlocksList, ContentBlock, ContentBlockTypeList } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ContentBlockService {

  constructor(private httpService: HttpService) {
  }

  getContentBlocks(verbose: boolean, page?: number, pageSize?: number) {
    const params = new HttpParams().set('verbose', String(verbose));
    return this.httpService.HttpGet<ContentBlocksList>(APIConstants.getContentBlocks, params);
  }

  getContentBlock(id: string) {
    return this.httpService.HttpGet<ContentBlock>(APIConstants.getContentBlock(id), null);
  }

  getContentBlockImage(id: string) {
    return this.httpService.HttpGet(APIConstants.getContentBlockImage(id), null, true);
  }

  getContentBlockTypes() {
    return this.httpService.HttpGet<ContentBlockTypeList>(APIConstants.getContentBlockTypes, null);
  }

  deleteContentBlock(id: string) {
    return this.httpService.HttpDelete<ContentBlock>(APIConstants.getContentBlock(id), null);
  }

  createContentBlock(title: string, subTitle: string, image: File, products: string[], type: string,
                     productLimit?: number, displayOrder?: number) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subTitle', subTitle);
    formData.append('image', image, image.name);
    products.forEach(product => {
      formData.append('products[]', product);
    });
    formData.append('type', type);
    if (productLimit && productLimit > 0) {
      formData.append('productLimit', String(productLimit));
    }
    if (displayOrder && displayOrder >= 0) {
      formData.append('displayOrder', String(displayOrder));
    }
    return this.httpService.HttpPost<FormData>(APIConstants.getContentBlocks, formData, null, true);
  }

  updateContentBlock(id: string, title: string, subTitle: string, image: File, products: string[], type: string,
                     productLimit?: number, displayOrder?: number) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subTitle', subTitle);
    if (image) {
      formData.append('image', image, image.name);
    }
    products.forEach(product => {
      formData.append('products[]', product);
    });
    formData.append('type', type);
    if (productLimit && productLimit > 0) {
      formData.append('productLimit', String(productLimit));
    }
    if (displayOrder && displayOrder >= 0) {
      formData.append('displayOrder', String(displayOrder));
    }
    return this.httpService.HttpPut<FormData>(APIConstants.getContentBlock(id), formData, null, true);
  }

  activateDeactivateBlock(id: string, isActivated: boolean) {
    if (isActivated === true) {
      return this.httpService.HttpPost(APIConstants.activateContentBlock(id), null, null);
    }
    return this.httpService.HttpPost(APIConstants.deactivateContentBlock(id), null, null);
  }
}
