import { Component, OnInit } from '@angular/core';
import {GlobalVariables} from '../../../helpers';
import {ReportsService} from '../../../services';
import {BrandAmount} from '../../../models';

declare const $;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public brandAmounts: BrandAmount[];
  public report: BrandAmount;

  constructor(
      private globalVariables: GlobalVariables,
      private reportsService: ReportsService,
      ) { }

  ngOnInit() {
    this.globalVariables.navBarTitle = 'Reports';
    this.fetchReportsBrandAmounts();
  }

  fetchReportsBrandAmounts() {
    this.reportsService.getReportsBrandAmounts()
        .subscribe((value) => {
          this.brandAmounts = value;
       });
  }

  showReportsModal(report: BrandAmount) {
    this.report = report;
    $('#reportsModal').modal('show');
  }

}
