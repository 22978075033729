import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Brand} from '../../../models';
import {BrandService, ConfirmDialogService, StoresService} from '../../../services';
import {Store, StoresList} from '../../../models/Stores';
import {GlobalVariables, RollingPageWindow} from '../../../helpers';

declare var $: any;


@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  // @Output() storesListChanged = new EventEmitter();
  //  @Input() showCreateStore = new EventEmitter();
  storesList: StoresList;
  stores: Store[];
  store: Store;
  currentPage = 1;
  totalPages = [];
  brands: Brand[] = [new Brand('', '', '', '', null, null, null, null, true)];

  public findPagesRollingWindow: (currentPage: number, totalPages: Array<number>) => (number[]);

  constructor(private storeService: StoresService,
              private brandService: BrandService,
              private confirmDialogService: ConfirmDialogService,
              private globalVariables: GlobalVariables
  ) {

    this.findPagesRollingWindow = RollingPageWindow.findPagesRollingWindow;
  }

  ngOnInit() {
    this.getStores(this.currentPage);
    this.getBrands();
    this.globalVariables.navBarTitle = 'Stores';
  }

  showCreateStore() {
    this.store = null;
    $('#storeModal').modal('show');
  }

  getStores(page: number, brand?: string,  ) {
    this.stores = [];
    this.totalPages = [];
    this.storeService.getStores(page, 10, brand, true)
      .subscribe(
        value => {
          this.storesList = value;
          for (let i = 1; i <= value.totalPages; i++) {
            this.totalPages.push(i);
          }
          this.stores = value.stores;
        }
      );
  }

  getBrands() {
    this.brandService.getBrands().subscribe((value) => {
      this.brands = this.brands.concat(value.brands);
    });
  }

  filterStores($event, page, brand) {
    console.log(page, brand);
    this.getStores(page, brand);
  }

  showEditStore(store: Store) {
    this.store = store;
    $('#storeModal').modal('show');
  }

  showConfirmDialog(id: string) {
    this.confirmDialogService.confirmThis('Confirm', 'Are you sure that you want to delete this Store?', 'Yes', 'No',
      () => {
        this.deleteStore(id);
      }, () => {
      });
  }

  deleteStore(id: string) {
    this.storeService.deleteStore(id).subscribe(
      value => {
        this.stores = this.stores.filter((store) => {
          return store._id !== id;
        });
      }
    );
  }

  activateDeactivateStore(store) {
    const isActivated = !store.isActivated;
    this.storeService.activateDeactivateStore(store._id, isActivated).subscribe(
        value => {
          store.isActivated = isActivated;
        }
    );
  }
}
