import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';
import { UsersList } from '../../models/User';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private httpService: HttpService) { }

    getUsers(page?: number, pageSize?: number, desc?: string, role?: string) {
        let params = new HttpParams();
        if (page) { params = params.append('page', String(page)); }
        if (pageSize) { params = params.append('pageSize', String(pageSize)); }
        if (desc) { params = params.append('desc', desc); }
        if (role) { params = params.append('role', role); }
        return this.httpService.HttpGet<UsersList>(APIConstants.getUsers, params);
    }
}
