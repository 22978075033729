export class LoginResult {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id: string,
        public token: string,
        public status: string,
        public firstname: any,
        public lastname: any
    ) { }
}
