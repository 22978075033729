import { Injectable } from '@angular/core';
import { CookiesService } from '../CookiesService/Cookies.service';
import { EncryptionDecryptionService } from '../EncryptionDecryptionService/EncryptionDecryption.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private cookiesService: CookiesService, private encryptionDecryptionService: EncryptionDecryptionService) { }

  setUser(token: string, userInfo: any) {
    this.setToken(token);
    this.setUserInfo(userInfo);
  }

  setUserInfo(userInfo: any) {
    this.cookiesService.set('user', JSON.stringify(userInfo));
  }

  getUserInfo() {
    const userInfo = this.cookiesService.get('user');
    if (userInfo) {
      return JSON.parse(userInfo);
    }

    return null;
  }

  setToken(token: string) {
    const encryptedToken = this.encryptionDecryptionService.set('123%%**456$#@$^@1ERF%^&$##@EDDF', token);
    this.cookiesService.set('token', encryptedToken);
  }

  getToken() {
    const token = this.cookiesService.get('token');
    if (token) {
      const decryptedToken = this.encryptionDecryptionService.get('123%%**456$#@$^@1ERF%^&$##@EDDF', token);
      return decryptedToken;
    }
    return null;
  }

  deleteUser() {
    this.deleteToken();
    this.deleteUserInfo();
  }

  deleteUserInfo() {
    this.cookiesService.delete('user');
  }

  deleteToken() {
    this.cookiesService.delete('token');
  }
}
