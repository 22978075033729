import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  previewFile(files): Observable<string> {
    return new Observable(obs => {
      if (files.length === 0) {
        return;
      }

      const file = files[0];
      if (file.type.startsWith('image/') && file.size > 10000000) {
        obs.error(new Error('Image size is too large; maximum size 10 MB.'));
        return;
      } else if (file.type.startsWith('video/') && file.size > 10000000) {
        obs.error(new Error('Video size is too large; maximum size 10 MB.'));
        return;
      }

      const reader = new FileReader();
      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result as string);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(file);
    });
  }
}
