import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { EventService, FileService, BrandService, ContentBlockService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { Event, Brand, ContentBlock } from '../../../models';
import { DateRange } from '../../../models/Event';
import { finalize } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-create-edit-event',
  templateUrl: './create-edit-event.component.html',
  styleUrls: ['./create-edit-event.component.scss']
})
export class CreateEditEventComponent implements OnInit, OnDestroy {
  @Output() createdEvent = new EventEmitter();
  @Input() event: Event;
  eventModal: any;
  title = 'Create Event';
  btnTitle = 'Create';
  brands: Brand[] = [];
  cblocks: ContentBlock[] = [];
  date = new DateRange(new Date());
  imageFile: File;
  imageUrl: string;
  additionalImagesFiles: File[] = [];
  additionalImagesUrl: string[] = [];
  videoFile: File;
  videoUrl: string;
  loading = false;
  errorMessages = ['', ''];
  totalImagesRows = new Array<number>(1);
  currentImageRow = 0;
  removedImages = []; // for update
  relationUrlFiles: { [id: string]: File; } = {};

  private emptyEvent: Event = {
    _id: '',
    name: '',
    description: '',
    date: new DateRange(new Date()),
    image: '',
    dateNote: null,
    location: null,
    additionalImages: null,
    video: null,
    brand: null,
    cblock: null
  };

  createEventForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    fromDatetime: ['', Validators.required],
    toDatetime: '',
    image: '',
    dateNote: '',
    location: '',
    additionalImages: [],
    video: '',
    brand: '',
    cblock: ''
  });

  constructor(private eventService: EventService,
              private brandService: BrandService,
              private contentBlockService: ContentBlockService,
              private fileService: FileService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.eventModal = $('#eventModal');
    this.eventModal.on('shown.bs.modal', () => {
      this.initEventModel();
    });

    this.eventModal.on('hidden.bs.modal', () => {
      this.initEventModel();
    });
  }

  ngOnDestroy(): void {
    if (this.eventModal) {
      this.eventModal.off('shown.bs.modal hidden.bs.modal');
    }
  }

  initEventModel() {
    const finalEvent = (this.event) ? this.event : this.emptyEvent;
    this.createEventForm.reset();
    this.createEventForm.get('name').setValue(finalEvent.name);
    this.createEventForm.get('description').setValue(finalEvent.description);
    this.createEventForm.get('dateNote').setValue(finalEvent.dateNote);
    this.createEventForm.get('location').setValue(finalEvent.location);
    this.createEventForm.get('fromDatetime').setValue(finalEvent.date.from);
    this.createEventForm.get('toDatetime').setValue(finalEvent.date.to);
    this.getBrands();
    this.getContentBlocks();
    this.imageFile = null;
    this.imageUrl = finalEvent.image || null;
    this.additionalImagesFiles = [];
    this.removedImages = [];
    this.additionalImagesUrl = Object.assign([], finalEvent.additionalImages) || [];
    this.videoFile = null;
    this.videoUrl = finalEvent.video || null;
    this.title = finalEvent._id === '' ? 'Create Event' : 'Update Event';
    this.btnTitle = finalEvent._id === '' ? 'Create' : 'Update';
    this.calculateTotalImagesRows();
    this.currentImageRow = 0;
  }

  getBrands() {
    this.brandService.getBrands().subscribe(
      value => {
        this.brands = value.brands;
        this.brands.unshift(new Brand('', '', '', '', null, null, null, null, true));
        const bValue = (this.event && this.event.brand && this.event.brand._id) || '';
        this.createEventForm.get('brand').setValue(bValue);
      });
  }

  getContentBlocks() {
    this.contentBlockService.getContentBlocks(false).subscribe(
      value => {
        this.cblocks = value.cblocks;
        this.cblocks.unshift(new ContentBlock('', '', '', null, [], null, false));
        const bValue = (this.event && this.event.cblock && this.event.cblock._id) || '';
        this.createEventForm.get('cblock').setValue(bValue);
      });
  }

  get name() {
    return this.createEventForm.get('name');
  }

  get description() {
    return this.createEventForm.get('description');
  }

  get fromDatetime() {
    return this.createEventForm.get('fromDatetime');
  }

  get toDatetime() {
    return this.createEventForm.get('toDatetime');
  }

  get dateNote() {
    return this.createEventForm.get('dateNote');
  }

  get location() {
    return this.createEventForm.get('location');
  }

  get brand() {
    return this.createEventForm.get('brand');
  }

  get cblock() {
    return this.createEventForm.get('cblock');
  }

  get image() {
    return this.createEventForm.get('image');
  }

  get video() {
    return this.createEventForm.get('video');
  }

  getDate() {
    const date = {
      from: this.fromDatetime.value,
      to: this.toDatetime.value
    };
    return date;
  }

  calculateTotalImagesRows() {
    const numberOfRows = this.additionalImagesUrl.length > 0 ? this.additionalImagesUrl.length / 3 : 1;
    this.totalImagesRows = new Array<number>(Math.ceil(numberOfRows));
    this.currentImageRow = this.currentImageRow > this.totalImagesRows.length - 1 ? this.totalImagesRows.length - 1 : this.currentImageRow;
  }

  showPrevImages() {
    this.currentImageRow = this.currentImageRow === 0 ? this.totalImagesRows.length - 1 : this.currentImageRow - 1;
  }

  showNextImages() {
    this.currentImageRow = this.currentImageRow === this.totalImagesRows.length - 1 ? 0 : this.currentImageRow + 1;
  }

  deleteSelectedImage(url: string) {
    if (this.event && this.event.additionalImages && this.event.additionalImages.indexOf(url) >= 0) {
      this.removedImages.push(url);
    }
    this.additionalImagesUrl = this.additionalImagesUrl.filter((image) => {
      return image !== url;
    });
    const file = this.relationUrlFiles[url];
    const index = this.additionalImagesFiles.indexOf(file);
    if (index > -1) {
      this.additionalImagesFiles.splice(index, 1);
    }
    this.calculateTotalImagesRows();
  }

  onSubmitEvent() {
    if (this.event && this.event._id) {
      this.updateEvent();
    } else {
      this.createEvent();
    }
  }

  createEvent() {
    this.loading = true;
    this.eventService.createEvent(this.name.value, this.description.value,
      this.getDate(), this.imageFile, this.brand.value, this.dateNote.value,
      this.location.value, this.additionalImagesFiles, this.videoFile, this.cblock.value
    ).pipe(finalize(() => this.loading = false))
      .subscribe(
        _ => {
          this.eventModal.modal('hide');
          this.createdEvent.emit();
        }
      );
  }

  updateEvent() {
    this.loading = true;
    this.eventService.updateEvent(this.event._id, this.name.value, this.description.value,
      this.getDate(), this.imageFile, this.brand.value, this.dateNote.value,
      this.location.value, this.additionalImagesFiles, this.videoFile,
      this.removedImages, this.cblock.value
    ).pipe(finalize(() => this.loading = false))
      .subscribe(
        _ => {
          this.eventModal.modal('hide');
          this.createdEvent.emit();
        }
      );
  }

  loadImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.imageFile = files[0];
        this.imageUrl = value;
        this.errorMessages[0] = '';
      },
      error => {
        this.errorMessages[0] = error.message;
        this.imageFile = null;
        this.imageUrl = null;
      }
    );
  }


  loadAdditionalImages(event, files) {
    for (const file of files) {
      this.fileService.previewFile([file])
        .subscribe(
          imageUrl => {
            if (this.additionalImagesUrl.indexOf(imageUrl) < 0) {
              this.additionalImagesFiles.push(file);
              this.additionalImagesUrl.push(imageUrl);
              this.relationUrlFiles[imageUrl] = file;
            }
            this.calculateTotalImagesRows();
            this.errorMessages[1] = '';
          },
          error => {
            this.errorMessages[1] = `Error with ${file.name} image.` + error.message;
          }
        );
    }
    event.srcElement.value = null;
  }

  loadVideo(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.videoFile = files[0];
        this.videoUrl = value;
        this.errorMessages[0] = '';
      },
      error => {
        this.errorMessages[0] = error.message;
        this.videoFile = null;
        this.videoUrl = null;
      }
    );
  }
}
