<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">GlobalShowcase Login</h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" required formControlName="email" class="form-control" 
                        [class.is-invalid]="submitted && email.errors" />
                    <div *ngIf="submitted && email.errors" class="invalid-feedback">
                        <div *ngIf="!email.value">Email is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" required formControlName="password" class="form-control" 
                        [class.is-invalid]="submitted && password.errors" />
                    <div *ngIf="submitted && password.errors" class="invalid-feedback">
                        <div *ngIf="!password.value">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="glosho-button btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>