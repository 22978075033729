<div class="row">
    <div class="table-responsive-md w-100">
        <table class="table table-bordered color-white">
            <thead>
            <tr>
                <th>Logo</th>
                <th>Name</th>
                <th class="hide">Average Price</th>
                <th class="hide">Count</th>
                <th>Products</th>
                <th class="hide">Total Price</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let brandAmount of brandAmounts">
                <td class="align-middle d-flex justify-content-center align-items-center hide">
                    <img class="logo" src="{{brandAmount.logo}}" alt="report brand amount logo">
                </td>
                <td class="align-middle">
                    <span>{{brandAmount.name}}</span>
                </td>
                <td class="align-middle hide">
                    <span class="mr-1">{{brandAmount.averagePrice | number : '1.0-2'}}</span>
                    <span>&#163;</span>
                </td>
                <td class="align-middle hide">
                    {{brandAmount.count}}
                </td>
                <td class="align-middle">
                    <button class="btn btn-light btn-lg" type="button"
                            aria-haspopup="true" aria-expanded="false" (click)="showReportsModal(brandAmount)">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                </td>
                <td class="align-middle hide">
                    <span class="mr-1">{{brandAmount.totalPrice | number : '1.0-2'}}</span>
                    <span>&#163;</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<app-reports-modal [report]="report"></app-reports-modal>
