<div class="modal fade" id="categoryModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="createCategoryModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="createCategoryForm" (ngSubmit)="onSubmitCategory()" novalidate>
            <div class="form-group">
              <label class="input-title" for="name">Name</label>
              <input placeholder="Enter Category Name" formControlName="name" type="text" class="form-control"
                name="name" required id="name" [class.is-invalid]="name.invalid && name.touched">
              <div *ngIf="name.invalid && name.touched">
                <small *ngIf="!name.value" class="text-danger">
                  Name is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="description">Description</label>
              <input placeholder="Enter Category Description" type="text" class="form-control" name="description"
                id="description" formControlName="description">
            </div>
            <div class="form-group">
              <label class="input-title" for="display">Display</label>
              <select formControlName="display" id="display" class="form-control" placeholder="Choose Display">
                <option [class.is-invalid]="display.invalid && display.touched"
                  *ngFor="let displayOption of displayOptions" value="{{displayOption.name}}">
                  {{displayOption.name}}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="input-title" for="portraitImage">Portrait Image</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Portrait Image" type="file" formControlName="portraitImage" #portrait
                  (change)="previewPortraitImage(portrait.files)" class="form-control input-upload-file"
                  name="portraitImage" id="portraitImage"
                  [class.is-invalid]="portraitImage.invalid && portraitImage.touched"
                  accept="image/jpeg, image/png, image/jpg">
                <div class="preview-image__container">
                  <img class="preview-image__image" [src]="portraitImageUrl" alt="preview of portrait image"
                    *ngIf="portraitImageUrl">
                </div>
              </div>
              <small *ngIf="portraitImage.touched && !portraitImageUrl" class="text-danger">
                Portrait Image is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[0]">{{errorMessages[0]}}</small>
            </div>
            <div class="form-group">
              <label class="input-title" for="landscapeImage">Landscape Image</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose landscape Image" type="file" formControlName="landscapeImage" #landscape
                  (change)="previewLandscapeImage(landscape.files)" accept="image/jpeg, image/png, image/jpg"
                  class="form-control input-upload-file" name="landscapeImage" id="landscapeImage"
                  [class.is-invalid]="landscapeImage.invalid && landscapeImage.touched">
                <div class="preview-image__container">
                  <img class="preview-image__image" [src]="landscapeImageUrl" alt="preview of landscape image"
                    *ngIf="landscapeImageUrl">
                </div>
              </div>
              <small *ngIf="landscapeImage.touched && !landscapeImageUrl" class="text-danger">
                Landscape Image is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[1]">{{errorMessages[1]}}</small>
            </div>
            <button [disabled]="loading || createCategoryForm.invalid || !portraitImageUrl || !landscapeImageUrl"
              class="glosho-button btn" type="submit">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{btnTitle}}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>