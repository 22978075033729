import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

constructor(private cookieService: CookieService) { }

set(name: string, value: string, expires?: Date, path?: string, domain?: string, secure?: boolean) {
  this.cookieService.set(name, value, expires, path, domain);
}

get(name: string) {
  return this.cookieService.get(name);
}

delete(name: string, path?: string, domain?: string) {
  this.cookieService.delete(name, path, domain);
}
}
