import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentBlockService, ConfirmDialogService, FileService } from '../../../services';
import { ContentBlock } from '../../../models';
import { GlobalVariables } from '../../../helpers';

declare var $: any;

@Component({
  selector: 'app-content-blocks',
  templateUrl: './content-blocks.component.html',
  styleUrls: ['./content-blocks.component.scss']
})
export class ContentBlocksComponent implements OnInit {
  public contentBlocks: ContentBlock[] = [];
  public contentBlocksImagesUrl: { [id: string]: string; } = {};

  constructor(private router: Router,
              private contentBlockService: ContentBlockService,
              private globalVariables: GlobalVariables,
              private confirmDialogService: ConfirmDialogService,
              private fileService: FileService) { }

  ngOnInit() {
    this.globalVariables.navBarTitle = 'Content Blocks';
    this.getContentBlocks();
  }

  getContentBlocks() {
    this.contentBlockService.getContentBlocks(true).subscribe(
      value => {
        this.contentBlocks = value.cblocks;
        this.getContentBlockImages();
      }
    );
  }

  getContentBlockImages() {
    this.contentBlocks.forEach(element => {
      this.contentBlockService.getContentBlockImage(element._id)
        .subscribe(image => {
          this.fileService.previewFile([image])
            .subscribe(
              imageUrl => {
                this.contentBlocksImagesUrl[element._id] = imageUrl;
              });
        });
    });
  }

  showConfirmDialog(id: string) {
    this.confirmDialogService.confirmThis('Confirm', 'Are you sure that you want to delete this content block?', 'Yes', 'No',
      () => {
        this.deleteContentBlock(id);
      }, () => {
      });
  }

  deleteContentBlock(id: string) {
    this.contentBlockService.deleteContentBlock(id).subscribe(
      value => {
        this.contentBlocks = this.contentBlocks.filter((cBlock) => {
          return cBlock._id !== id;
        });
      }
    );
  }

  activateDeactivateBlock(cBlock) {
    const isActivated = !cBlock.isActivated;
    this.contentBlockService.activateDeactivateBlock(cBlock._id, isActivated).subscribe(
        value => {
          cBlock.isActivated = isActivated;
        }
    );
  }

  showCreateContentBlock() {
    this.router.navigate(['/adminPanel/create-edit-content-block']);
  }

  showEditContentBlock(contentBlock: ContentBlock) {
    this.router.navigate(['/adminPanel/create-edit-content-block', { contentBlockId: contentBlock._id }]);
  }
}
