export class CategoriesList {
  constructor(
    public categories: Category[],
    public totalDocs: number,
    public pageSize: number,
    public page: number,
    public totalPages: number,
    public pagingCounter: number,
    public hasPrevPage: boolean,
    public hasNextPage: boolean,
    public prevPage: boolean,
    public nextPage: boolean
  ) { }
}

export class Category {
  constructor(
    // tslint:disable-next-line:variable-name
    public _id: string,
    public name: string,
    public description: string = null,
    public landscapeImage: any,
    public portraitImage: any,
    public isActivated: boolean,
    public display?: string,
  ) { }
}
