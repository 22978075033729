export class ProductsList {
    constructor(
        public listInfo: HeaderProductInfo,
        public products: Product[],
        public totalDocs: number,
        public pageSize: number,
        public page: number,
        public totalPages: number,
        public pagingCounter: number,
        public hasPrevPage: boolean,
        public hasNextPage: boolean,
        public prevPage: boolean,
        public nextPage: boolean
    ) { }
}

export class HeaderProductInfo {
    constructor(
        public count: number = 0,
        public type?: string,
        public title?: string,
        public image?: string,
        public logo?: string,
        public description?: string,
        public display: string = '.XY'
    ) { }
}

export class Product {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id: string,
        public name: string,
        public description: string,
        public image: string,
        public category: string,
        public brand: ProductBrand,
        public display: string,
        public isActivated: boolean,
        public shortDescription?: string,
        public productNo?: string,
        public additionalImages?: string[],
        public price?: ProductPrice,
        public review?: ProductReview,
        public rewardPoints?: number,
        public order?: number,
        public selected?: boolean
    ) { }
}

export class ProductPrice {
    constructor(
        public fixed?: number,
        public vat?: number,
        public bestPriceGuarantee?: boolean
    ) { }
}

export class ProductBrand {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id: string,
        public name: string,
        public video?: string,
        public storefrontImage?: string
    ) { }
}

export class ProductReview {
    constructor(
        public stars?: number,
        public reviewerName?: string,
        public text?: string
    ) { }
}
