import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ProductService, FileService, CategoryService, BrandService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { Product, Category, Brand, ProductPrice, ProductReview } from '../../../models';
import { finalize } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-create-edit-product',
  templateUrl: './create-edit-product.component.html',
  styleUrls: ['./create-edit-product.component.scss']
})
export class CreateEditProductComponent implements OnInit, OnDestroy {
  @Output() createdProduct = new EventEmitter();
  @Input() product: Product;
  categories: Category[] = [];
  brands: Brand[] = [];
  loading = false;
  title = 'Create Product';
  btnTitle = 'Create';
  productModal: any;
  imageFile: File;
  additionalImagesFiles: File[] = [];
  imageUrl: any;
  additionalImagesUrl = [];
  errorMessages = ['', ''];
  starsArray = ['', '1', '2', '3', '4', '5'];
  totalImagesRows = new Array<number>(1);
  currentImageRow = 0;
  removedImages = []; // for update
  relationUrlFiles: { [id: string]: File; } = {};

  private emptyProduct: Product = {
    _id: '',
    name: '',
    description: '',
    image: null,
    additionalImages: null,
    brand: new Brand('', '', '', '', '', '', '', '', true),
    category: '',
    shortDescription: '',
    productNo: '',
    rewardPoints: 0,
    order: 0,
    display: '',
    price: new ProductPrice(),
    review: new ProductReview(),
    isActivated: true
  };

  createProductForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    image: '',
    brand: ['', Validators.required],
    category: ['', Validators.required],
    shortDescription: '',
    productNo: '',
    rewardPoints: 0,
    order: 0,
    fixed: 0,
    bestPriceGuarantee: false,
    reviewerName: '',
    stars: '',
    reviewText: ''
  });

  constructor(private productService: ProductService,
              private categoryService: CategoryService,
              private brandService: BrandService,
              private fileService: FileService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.setPriceValidators();
    this.setReviewValidators();
    this.productModal = $('#productModal');
    this.productModal.on('shown.bs.modal', () => {
      this.initProductModel();
    });

    this.productModal.on('hidden.bs.modal', () => {
      this.initProductModel();
    });
  }

  setPriceValidators() {
    this.bestPriceGuarantee.valueChanges
      .subscribe(bestPriceGuarantee => {
        if (bestPriceGuarantee === true) {
          this.fixed.setValidators([Validators.required, Validators.pattern('[0-9]*')]);
        } else {
          this.fixed.setValidators([Validators.pattern('[0-9]*')]);
        }

        this.fixed.updateValueAndValidity();
      });
  }

  setReviewValidators() {
    this.reviewerName.valueChanges
      .subscribe(name => {
        if (name) {
          this.stars.setValidators([Validators.required]);
        } else {
          this.stars.setValidators(null);
        }

        this.stars.updateValueAndValidity();
      });

    this.reviewText.valueChanges
      .subscribe(text => {
        if (text) {
          this.stars.setValidators([Validators.required]);
        } else {
          this.stars.setValidators(null);
        }
        this.stars.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    if (this.productModal) {
      this.productModal.off('shown.bs.modal hidden.bs.modal');
    }
  }

  initProductModel() {
    const finalProduct = (this.product) ? this.product : this.emptyProduct;
    this.createProductForm.reset();
    this.createProductForm.get('name').setValue(finalProduct.name);
    this.createProductForm.get('description').setValue(finalProduct.description);
    this.createProductForm.get('shortDescription').setValue(finalProduct.shortDescription || '');
    this.createProductForm.get('productNo').setValue(finalProduct.productNo || '');
    this.createProductForm.get('rewardPoints').setValue(finalProduct.rewardPoints || 0);
    this.createProductForm.get('order').setValue(finalProduct.order || 0);
    this.createProductForm.get('fixed').setValue((finalProduct.price && finalProduct.price.fixed) || 0);
    this.createProductForm.get('bestPriceGuarantee').setValue((finalProduct.price && finalProduct.price.bestPriceGuarantee) || false);
    this.createProductForm.get('reviewerName').setValue((finalProduct.review && finalProduct.review.reviewerName) || '');
    this.createProductForm.get('stars').setValue((finalProduct.review && finalProduct.review.stars) || '');
    this.createProductForm.get('reviewText').setValue((finalProduct.review && finalProduct.review.text) || '');

    this.imageFile = null;
    this.additionalImagesFiles = [];
    this.removedImages = [];
    this.imageUrl = finalProduct.image || null;
    this.additionalImagesUrl = Object.assign([], finalProduct.additionalImages) || [];
    this.title = finalProduct._id === '' ? 'Create Product' : 'Update Product';
    this.btnTitle = finalProduct._id === '' ? 'Create' : 'Update';
    this.getCategories();
    this.getBrands();
    this.calculateTotalImagesRows();
    this.currentImageRow = 0;
  }

  getCategories() {
    this.categoryService.getCategories(1, 100).subscribe( // FIXME: This only fetches the first 100 categories.
      value => {
        this.categories = value.categories;
        const cValue = (this.product && this.product.category) || (this.categories && this.categories.length > 0 ?
          this.categories[0]._id : '');
        this.createProductForm.get('category').setValue(cValue);
      }
    );
  }

  getBrands() {
    this.brandService.getBrands().subscribe(
      value => {
        this.brands = value.brands;
        const bValue = (this.product && this.product.brand && this.product.brand._id) || (this.brands && this.brands.length > 0 ?
          this.brands[0]._id : '');
        this.createProductForm.get('brand').setValue(bValue);
      });
  }

  get name() {
    return this.createProductForm.get('name');
  }

  get description() {
    return this.createProductForm.get('description');
  }

  get brand() {
    return this.createProductForm.get('brand');
  }

  get category() {
    return this.createProductForm.get('category');
  }

  get image() {
    return this.createProductForm.get('image');
  }

  get shortDescription() {
    return this.createProductForm.get('shortDescription');
  }

  get productNo() {
    return this.createProductForm.get('productNo');
  }

  get rewardPoints() {
    return this.createProductForm.get('rewardPoints');
  }

  get order() {
    return this.createProductForm.get('order');
  }

  get fixed() {
    return this.createProductForm.get('fixed');
  }

  get bestPriceGuarantee() {
    return this.createProductForm.get('bestPriceGuarantee');
  }

  get reviewerName() {
    return this.createProductForm.get('reviewerName');
  }

  get stars() {
    return this.createProductForm.get('stars');
  }

  get reviewText() {
    return this.createProductForm.get('reviewText');
  }

  calculateTotalImagesRows() {
    const numberOfRows = this.additionalImagesUrl.length > 0 ? this.additionalImagesUrl.length / 3 : 1;
    this.totalImagesRows = new Array<number>(Math.ceil(numberOfRows));
    this.currentImageRow = this.currentImageRow > this.totalImagesRows.length - 1 ? this.totalImagesRows.length - 1 : this.currentImageRow;
  }

  showPrevImages() {
    this.currentImageRow = this.currentImageRow === 0 ? this.totalImagesRows.length - 1 : this.currentImageRow - 1;
  }

  showNextImages() {
    this.currentImageRow = this.currentImageRow === this.totalImagesRows.length - 1 ? 0 : this.currentImageRow + 1;
  }

  deleteSelectedImage(url: string) {
    if (this.product && this.product.additionalImages && this.product.additionalImages.indexOf(url) >= 0) {
      this.removedImages.push(url);
    }
    this.additionalImagesUrl = this.additionalImagesUrl.filter((image) => {
      return image !== url;
    });
    const file = this.relationUrlFiles[url];
    const index = this.additionalImagesFiles.indexOf(file);
    if (index > -1) {
      this.additionalImagesFiles.splice(index, 1);
    }
    this.calculateTotalImagesRows();
  }

  onSubmitProduct() {
    if (this.product && this.product._id) {
      this.updateProduct();
    } else {
      this.createProduct();
    }
  }

  getPriceObject() {
    const price: any = {};
    if (this.fixed.value || this.fixed.value === 0) {
      price.fixed = this.fixed.value;
      price.bestPriceGuarantee = this.bestPriceGuarantee.value;
      return price;
    }

    return '';
  }

  getReviewObject() {
    const review: any = {};
    if (this.stars.value) {
      review.stars = this.stars.value;
      if (this.reviewerName.value) {
        review.reviewerName = this.reviewerName.value;
      }
      if (this.reviewText.value) {
        review.text = this.reviewText.value;
      }
      return review;
    }

    return '';
  }

  createProduct() {
    this.loading = true;
    this.productService.createProduct(this.name.value, this.description.value,
      this.imageFile, this.category.value, this.brand.value, this.additionalImagesFiles,
      this.shortDescription.value, this.productNo.value, this.rewardPoints.value, this.order.value,
      this.getPriceObject(), this.getReviewObject())
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.productModal.modal('hide');
          this.createdProduct.emit();
        }
      );
  }

  updateProduct() {
    this.loading = true;
    this.productService.updateProduct(this.product._id, this.name.value, this.description.value,
      this.imageFile, this.category.value, this.brand.value, this.additionalImagesFiles,
      this.shortDescription.value, this.productNo.value, this.rewardPoints.value || 0, this.order.value || 0,
      this.getPriceObject(), this.getReviewObject(), this.removedImages)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.productModal.modal('hide');
          this.createdProduct.emit();
        }
      );
  }

  loadImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.imageFile = files[0];
        this.imageUrl = value;
        this.errorMessages[0] = '';
      },
      error => {
        this.errorMessages[0] = error.message;
        this.imageFile = null;
        this.imageUrl = null;
      }
    );
  }

  loadAdditionalImages(event, files) {
    for (const file of files) {
      this.fileService.previewFile([file])
        .subscribe(
          imageUrl => {
            if (this.additionalImagesUrl.indexOf(imageUrl) < 0) {
              this.additionalImagesFiles.push(file);
              this.additionalImagesUrl.push(imageUrl);
              this.relationUrlFiles[imageUrl] = file;
            }
            this.calculateTotalImagesRows();
            this.errorMessages[1] = '';
          },
          error => {
            this.errorMessages[1] = `Error with ${file.name} image.` + error.message;
          }
        );
    }
    event.srcElement.value = null;
  }
}
