import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';
import { CategoriesList, Category } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private httpService: HttpService) { }
  getCategories(page: number, pageSize?: number) {
    let  params = new  HttpParams().set('page', String(page));
    if (pageSize) { params = params.append('pageSize', String(pageSize)); }
    return this.httpService.HttpGet<CategoriesList>(APIConstants.getCategories, params);
  }

  deleteCategory(id: string) {
    return this.httpService.HttpDelete<Category>(APIConstants.getCategory(id), null);
  }

  createCategory(name: string, description: string, portraitImage: File, landscapeImage: File, display: string) {
    const formData = new FormData();
    formData.append('name', name);
    if (description) { formData.append('description', description); }
    formData.append('portraitImage', portraitImage, portraitImage.name);
    formData.append('landscapeImage', landscapeImage, landscapeImage.name);
    if (display) { formData.append('display', display); }
    return this.httpService.HttpPost<FormData>(APIConstants.createCategory, formData, null, true);
  }

  updateCategory(id: string, name: string, description: string, portraitImage: File, landscapeImage: File, display: string) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (portraitImage) { formData.append('portraitImage', portraitImage, portraitImage.name); }
    if (landscapeImage) { formData.append('landscapeImage', landscapeImage, landscapeImage.name); }
    formData.append('display', display);
    return this.httpService.HttpPut<FormData>(APIConstants.getCategory(id), formData, null, true);
  }

  activateDeactivateCategory(id: string, isActivated: boolean) {
    if (isActivated === true) {
      return this.httpService.HttpPost(APIConstants.activateCategory(id), null, null);
    }
    return this.httpService.HttpPost(APIConstants.deactivateCategory(id), null, null);
  }
}
