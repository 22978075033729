import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorMessages } from './HttpErrorMessages';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            if (!navigator.onLine) {
                // Handle offline error
            } else {
                // Handle Http Error (error.status === 403, 404...)
                console.error(
                    `Backend returned code ${error.status}, ` +
                    `body was: ${JSON.stringify(error.error)}`);
                this.showErrorMessage(error);
            }
        } else {
            // Handle Client Error (Angular Error, ReferenceError...)
            console.error('An error occurred:', error.message);
        }
    }

    private showErrorMessage(error: HttpErrorResponse) {
        const toastrService = this.injector.get(ToastrService);
        const router = this.injector.get(Router);
        toastrService.error(HttpErrorMessages.getUserFriendlyMessage(error.error.error), 'Error', {
            closeButton: true
        }).onHidden
            .subscribe(() => {
                if (error.status === 401 || error.status === 403) {
                    router.navigate(['/login']);
                }
            });
    }
}
