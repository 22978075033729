import { Product } from './Product';

export class ContentBlocksList {
    constructor(public cblocks: ContentBlock[]) { }
}

export class ContentBlock {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id: string,
        public title: string,
        public subTitle: string,
        public image: any,
        public products: Product[],
        public type: ContentBlockType,
        public isActivated: boolean,
        public productLimit?: number,
        public displayOrder?: number
    ) { }
}

export class ContentBlockTypeList {
    constructor(public cblockTypes: ContentBlockType[]) { }
}

export class ContentBlockType {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id: string,
        public code: string,
        public description: string
    ) { }
}
