import { Injectable } from '@angular/core';
import {HttpService} from '../HttpService/Http.service';
import { BrandAmount } from '../../models';
import {APIConstants} from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {


  constructor(private httpService: HttpService) { }

  getReportsBrandAmounts() {
    return this.httpService.HttpGet<BrandAmount[]>(APIConstants.getReportsBrandAmounts, null, false);
  }
}
