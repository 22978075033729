import { Component, OnInit, EventEmitter } from '@angular/core';
import { ProductService } from '../../../services';
import { Product, ProductPrice, ProductReview, Brand } from '../../../models';
import { GlobalVariables } from '../../../helpers';

declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  createProductEvent = new EventEmitter();
  isSelectMode = false;

  constructor(private productService: ProductService,
              private globalVariables: GlobalVariables) { }

  ngOnInit() {
    this.globalVariables.navBarTitle = 'Products';
  }

  showCreateProduct() {
    this.createProductEvent.next();
  }
}
