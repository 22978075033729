import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Brand, Store, StoreBrands } from '../../../models';
import { FormBuilder, Validators } from '@angular/forms';
import {BrandService, StoresService} from '../../../services';
import {GeocodingService} from '../../../services/GeocodingService/geocoding.service';
import {Location} from '../../../models/Location';
import {finalize} from 'rxjs/operators';

declare const $: any;

@Component({
  selector: 'app-create-edit-store',
  templateUrl: './create-edit-store.component.html',
  styleUrls: ['./create-edit-store.component.scss']
})

export class CreateEditStoreComponent implements OnInit, OnDestroy {
  @Output() createdStore = new EventEmitter();
  @Input() store: Store;
  storeModal: any;
  latitude: number;
  longitude: number;
  isMapVisible = false;
  brands: any[] = [];
  mapIsLoading = false;
  loading = false;

  private emptyStore: Store = {
    _id: '',
    name: '',
    streetAddress: '',
    additionalStreetAddress: '',
    postcode: '',
    city: '',
    country: '',
    location: [],
    phone: '',
    hours: '',
    brands: [],
    isActivated: true
  };

  createStoreForm = this.formBuilder.group({
    name: ['', Validators.required],
    streetAddress: ['', Validators.required],
    additionalStreetAddress: '',
    postcode: ['', Validators.required],
    city: ['', Validators.required],
    country: ['', Validators.required],
    location: [],
    phone: '',
    hours: ['', Validators.required],
    selectedBrands: ['', Validators.required]
  });

  constructor(
    private brandService: BrandService,
    private formBuilder: FormBuilder,
    private geocodingService: GeocodingService,
    private storesService: StoresService
  ) { }

  ngOnInit() {
    this.fetchBrands();
    this.storeModal = $('#storeModal');
    this.storeModal.on('shown.bs.modal', () => {
       this.initStoreModel();
    });
    this.storeModal.on('hidden.bs.modal', () => {
      this.initStoreModel();
    });
  }

  fetchBrands() {
    this.brandService.getBrands().subscribe(val => {
      this.brands = val.brands;
    });
  }

  ngOnDestroy(): void {
      if (this.storeModal) {
          this.storeModal.off('shown.bs.modal hidden.bs.modal');
      }
  }

  initStoreModel() {
      const finalStore = this.store ? this.store : this.emptyStore;
      this.createStoreForm.reset();
      this.longitude = finalStore && finalStore.location && finalStore.location[0];
      this.latitude = finalStore && finalStore.location && finalStore.location[1];
      this.name.setValue(finalStore.name);
      this.streetAddress.setValue(finalStore.streetAddress);
      this.additionalStreetAddress.setValue(finalStore.additionalStreetAddress || '');
      this.postcode.setValue(finalStore.postcode);
      this.city.setValue(finalStore.city);
      this.country.setValue(finalStore.country);
      this.phone.setValue(finalStore.phone || '');
      this.hours.setValue(finalStore.hours);
      this.selectedBrands.setValue(finalStore.brands.map((brand) => {
              return brand._id;
          }));
      if (this.longitude && this.latitude && this.store) {
          this.isMapVisible = this.allAddressFieldsValid;
      }
  }

  getLocationCoords() {
    if (this.allAddressFieldsValid) {
      this.isMapVisible = false;
      this.mapIsLoading = true;
      const address =
          `${this.streetAddress.value.trim()},${this.postcode.value.trim()},${this.city.value.trim()},${this.country.value.trim()}`;
      this.geocodingService.geocodeAddress(address)
          .toPromise().then((location: Location) => {
          console.log(location);
          this.mapIsLoading = false;
          this.latitude = location.lat;
          this.longitude = location.lng;
          this.isMapVisible = true;
        });
    }
  }

  submitForm() {
      const data: any = {
          name: this.name.value.trim(),
          streetAddress: this.streetAddress.value.trim(),
          postcode: this.postcode.value.trim(),
          city: this.city.value.trim(),
          country: this.country.value.trim(),
          hours: this.hours.value.trim(),
          additionalStreetAddress: this.additionalStreetAddress.value.trim(),
          phone: this.phone.value.trim(),
          brands: this.selectedBrands.value
      };
      if (this.latitude && this.longitude) {
          data.location = [this.longitude, this.latitude];
      }
      if (this.store && this.store._id) {
          this.updateStore(data, this.store._id);
      } else {
          this.createStore(data);
      }
  }

  createStore(data) {
      this.loading = true;
      this.storesService.createStore(
          data.name,
          data.streetAddress,
          data.postcode,
          data.city,
          data.country,
          data.hours,
          data.brands,
          data.additionalStreetAddress,
          data.location,
          data.phone)
          .pipe(finalize(() => { this.loading = false; }))
          .subscribe((value) => {
              this.storeModal.modal('hide');
              this.createdStore.emit();
      });
  }

  updateStore(data, id) {
    this.loading = true;
    this.storesService.updateStore(
        id,
        data.name,
        data.streetAddress,
        data.postcode,
        data.city,
        data.country,
        data.hours,
        data.brands,
        data.additionalStreetAddress,
        data.location,
        data.phone)
        .pipe(finalize(() => {this.loading = false; }))
        .subscribe( (value) => {
            this.storeModal.modal('hide');
            this.createdStore.emit();
        });
  }

  get modalTitle() {
      if (this.store && this.store._id) {
          return 'Update Store';
      } else {
          return 'Create Store';
      }
  }

  get buttonTitle() {
      if (this.store && this.store._id) {
          return 'Update';
      } else {
          return 'Create';
      }
  }

  get allAddressFieldsValid() {
    return !this.city.invalid && !this.streetAddress.invalid && !this.country.invalid && !this.postcode.invalid;
  }

  get name() {
    return this.createStoreForm.get('name');
  }

  get streetAddress() {
    return this.createStoreForm.get('streetAddress');
  }

  get additionalStreetAddress() {
    return this.createStoreForm.get('additionalStreetAddress');
  }

  get postcode() {
    return this.createStoreForm.get('postcode');
  }

  get city() {
    return this.createStoreForm.get('city');
  }

  get country() {
    return this.createStoreForm.get('country');
  }

  get phone() {
    return this.createStoreForm.get('phone');
  }

  get hours() {
    return this.createStoreForm.get('hours');
  }

  get selectedBrands() {
    return this.createStoreForm.get('selectedBrands');
  }

}
