<div class="modal fade bd-example-modal-lg" id="productModal" tabindex="-1" role="dialog"
  data-target=".bd-example-modal-lg" data-backdrop="static" data-keyboard="false" aria-labelledby="createBrandModal"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Select Products</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <app-products-list [isSelectMode]="isSelectMode" [selectedProducts]="selectedProducts" (productsListChanged)="getSelectedProductsList($event)">
          </app-products-list>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="glosho-button btn btn-lg" (click)="selectProductsList()">Select</button>
      </div>
    </div>
  </div>
</div>