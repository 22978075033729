import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  private subject = new Subject<any>();
  constructor() { }

  confirmThis(title: string, message: string, confirmText: string, closeText: string,
              confirmed: () => void, closed: () => void) {
    this.setConfirmation(title, message, confirmText, closeText, confirmed, closed);
  }

  setConfirmation(title: string, message: string, confirmText: string, closeText: string,
                  confirmed: () => void, closed: () => void) {
    this.subject.next({
      title,
      text: message,
      confirmText,
      closeText,
      confirmed: () => {
        this.subject.next(); // this will close the modal
        confirmed();
      },
      closed: () => {
        this.subject.next(); // this will close the modal
        closed();
      }
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
