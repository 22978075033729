<div class="row no-gutters justify-content-end">
    <div class="col-sm-3">
        <div class="form-group">
            <label for="role" class="font-weight-bold">Role</label>
            <select id="role" class="form-control" name="role" #role
                    (change)="currentPage = 1; filterUsers($event, currentPage, desc.value, role.value)">
                <option *ngFor="let role of roles" value="{{role}}">
                    {{role}}
                </option>
            </select>
        </div>
    </div>

    <div class="col-sm-3 pt-2">
        <label for="search"></label>
        <input id="search" type="text" class="form-control" name="desc" #desc
               (keyup.enter)="currentPage = 1; filterUsers($event, currentPage, desc.value, role.value)"
               placeholder="Search..">
    </div>
</div>

<div class="table-responsive-md w-100">
    <table class="table table-bordered color-white">
        <thead>
        <tr>
            <th class="hide">Firstname</th>
            <th class="hide">Lastname</th>
            <th class="hide">Email</th>
            <th class="hide">Phone</th>
            <th class="hide">Role</th>
            <th class="hide">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users">
            <td class="align-middle hide">{{user.firstname}}</td>
            <td class="align-middle hide">{{user.lastname}}</td>
            <td class="align-middle hide">{{user.email}}</td>
            <td class="align-middle hide">{{user.phone}}</td>
            <td class="align-middle hide">{{user.role}}</td>
            <td class="align-middle hide">{{user.status}}</td>
        </tr>
        </tbody>
    </table>
</div>
<nav aria-label="..." class="table-responsive">
    <ul class="pagination flex-wrap justify-content-end">
        <li class="page-item" [ngClass]="{'disabled': currentPage == 1 }">
            <a class="page-link cursor"
               (click)="currentPage = 1; filterUsers($event, currentPage, desc.value, role.value)">
                First
            </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': !usersList || (usersList && !usersList.hasPrevPage)}">
            <a class="page-link cursor"
               (click)="currentPage = currentPage - 1; filterUsers($event, currentPage, desc.value, role.value)">
                Prev
            </a>
        </li>
        <li *ngFor="let page of findPagesRollingWindow(currentPage, this.totalPages); let i = index" class="page-item" [ngClass]="{'active': page === currentPage}">
            <a class="page-link cursor" (click)="currentPage = page; filterUsers($event, currentPage, desc.value, role.value)">
                {{page}}
            </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': !usersList || (usersList && !usersList.hasNextPage)}">
            <a class="page-link cursor"
               (click)="currentPage = currentPage + 1; filterUsers($event, currentPage, desc.value, role.value)">
                Next
            </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages.length }">
            <a class="page-link cursor"
               (click)="currentPage = totalPages.length; filterUsers($event, currentPage, desc.value, role.value)">
                Last
            </a>
        </li>
    </ul>
</nav>

