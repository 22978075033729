import { Component, OnInit } from '@angular/core';
import { CategoryService, ConfirmDialogService } from '../../../services';
import { Category, CategoriesList } from '../../../models';
import { GlobalVariables, RollingPageWindow } from '../../../helpers';

declare var $: any;

@Component({
  selector: 'app-category',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categoriesList: CategoriesList;
  categories: Category[];
  category: Category;
  currentPage = 1;
  totalPages: number[];

  public findPagesRollingWindow: (currentPage: number, totalPages: Array<number>) => (number[]);

  constructor(private categoryService: CategoryService,
              private globalVariables: GlobalVariables,
              private confirmDialogService: ConfirmDialogService) {

    this.findPagesRollingWindow = RollingPageWindow.findPagesRollingWindow;
  }

  ngOnInit() {
    this.globalVariables.navBarTitle = 'Categories';
    this.getCategories(this.currentPage);
  }

  getCategories(page: number) {
    this.categoryService.getCategories(page, 10).subscribe(
      value => {
        this.categoriesList = value;
        this.totalPages = new Array<number>(value.totalPages);
        this.categories = value.categories;
      }
    );
  }

  filterCategories($event, page) {
    console.log(page);
    this.getCategories(page);
  }

  showConfirmDialog(id: string) {
    this.confirmDialogService.confirmThis('Confirm', 'Are you sure that you want to delete this category?', 'Yes', 'No',
    () => {
      this.deleteCategory(id);
    }, () => {
    });
  }

  deleteCategory(id: string) {
    this.categoryService.deleteCategory(id).subscribe(
      value => {
        this.categories = this.categories.filter((category) => {
          return category._id !== id;
        });
      }
    );
  }

  showCreateCategory() {
    this.category = new Category('', '', '', null, null, true, 'XY');
    $('#categoryModal').modal('show');
  }

  showEditCategory(category: Category) {
    this.category = category;
    $('#categoryModal').modal('show');
  }

  activateDeactivateCategory(category) {
    const isActivated = !category.isActivated;
    this.categoryService.activateDeactivateCategory(category._id, isActivated).subscribe(
        value => {
          category.isActivated = isActivated;
        }
    );
  }
}
