import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, AccountService, FileService } from '../../../services';
import { GlobalVariables } from '../../../helpers';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Output() sidebarToggled = new EventEmitter();

  accountImageUrl: any;

  constructor(private authenticationService: AuthenticationService,
              private accountService: AccountService,
              private fileService: FileService,
              private router: Router,
              public globalVariables: GlobalVariables
  ) { }

  ngOnInit() {
    this.getAccountImage();
  }

  getAccountImage() {
    this.accountService.getAccountImage()
    .subscribe(image => {
      this.fileService.previewFile([image])
      .subscribe(
        imageUrl => {
          this.accountImageUrl = imageUrl;
        });
    }, () => {
      // Do not throw error if image is not found;
      return;
    });
  }

  get firstName() {
    return this.accountService.getUserName();
  }

  logout() {
    this.authenticationService.deleteUser();
    this.router.navigate(['/login']);
  }

   sidebarToggleButtonClicked() {
    this.sidebarToggled.emit();
   }
}
