<div class="modal fade" id="reportsModal" tabIndex="-1" role="dialog" aria-labelledby="show Report" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" *ngIf="report">
            <div class="modal-header">
                <h5 class="modal-title" id="showReportsModalTitle">{{title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-center align-items-center p-1">
                    <h5 class="p-1">Logo</h5>
                </div>
                <div class="d-flex justify-content-center align-items-center p-1">
                    <img src="{{report.logo}}" alt="report logo" class="report-logo p-1">
                </div>
                <hr/>
                <div class="d-flex justify-content-center align-items-center p-1">
                    <h5 class="p-1">Name</h5>
                </div>
                <div class="d-flex justify-content-center align-items-center p-1">
                    <span class="p-1">{{report.name}}</span>
                </div>
                <hr/>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <h5 class="p-1">Average Price</h5>
                    </div>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <span class="p-1 mr-1">{{report.averagePrice | number : '1.0-2'}}</span>
                        <span>&#163;</span>
                    </div>
                    <hr/>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <h5 class="p-1">Count</h5>
                    </div>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <span class="p-1">{{report.count}}</span>
                    </div>
                <hr/>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <h5 class="p-1">Products</h5>
                    </div>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <ul class="product-list p-1">
                            <li class="m-1" *ngFor="let product of report.products">
                                <div class="d-flex justify-content-start align-items-center">
                                    <img src="{{product.image}}" alt="image of product" class="product-logo pr-1">
                                    <span class="pl-1 text-overflow-ellipsis">{{product.name}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                <hr/>
                <div class="d-flex justify-content-center align-items-center p-1">
                    <h5 class="p-1">Total Price</h5>
                </div>
                <div class="d-flex justify-content-center align-items-center p-1">
                    <span class="p-1 mr-1">{{report.totalPrice | number : '1.0-2'}}</span>
                    <span>&#163;</span>
                </div>
            </div>
        </div>
    </div>
</div>
