<div class="modal fade bd-example-modal-lg" id="eventModal" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false" data-target=".bd-example-modal-lg" aria-labelledby="createEventModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="createEventForm" (ngSubmit)="onSubmitEvent()" novalidate>
            <div class="form-group">
              <label class="input-title" for="name">Name</label>
              <input placeholder="Enter Event Name" formControlName="name" type="text" class="form-control"
                name="name" required id="name" [class.is-invalid]="name.invalid && name.touched">
              <div *ngIf="name.invalid && name.touched">
                <small *ngIf="!name.value" class="text-danger">
                  Name is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="description">Description</label>
              <input placeholder="Enter Event Description" type="text" class="form-control" name="description"
                id="description" required formControlName="description"
                [class.is-invalid]="description.invalid && description.touched">
              <div *ngIf="description.invalid && description.touched">
                <small *ngIf="!description.value" class="text-danger">
                  Description is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="fromDatetime">From</label>
              <input [owlDateTimeTrigger]="eventfromdt" [owlDateTime]="eventfromdt"
                placeholder="Select Event From Date" type="text" class="form-control" name="fromDatetime"
                id="fromDatetime" required formControlName="fromDatetime"
                [class.is-invalid]="fromDatetime.invalid && fromDatetime.touched">
              <owl-date-time #eventfromdt></owl-date-time>
              <div *ngIf="fromDatetime.invalid && fromDatetime.touched">
                <small class="text-danger">
                  From date is empty or invalid
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="toDatetime">To</label>
              <input [owlDateTimeTrigger]="eventtodt" [owlDateTime]="eventtodt"
                placeholder="Select Event To Date" type="text" class="form-control" name="toDatetime"
                id="toDatetime" formControlName="toDatetime"
                [class.is-invalid]="toDatetime.invalid && toDatetime.touched">
              <owl-date-time #eventtodt></owl-date-time>
              <div *ngIf="toDatetime.invalid && toDatetime.touched">
                <small class="text-danger">
                  To date is invalid
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="dateNote">Date Note</label>
              <input placeholder="Enter Event Date Note" type="text" class="form-control" name="dateNote"
                id="dateNote" formControlName="dateNote"
                [class.is-invalid]="dateNote.invalid && dateNote.touched">
            </div>
            <div class="form-group">
              <label class="input-title" for="location">Location</label>
              <input placeholder="Enter Event Location" type="text" class="form-control" name="location"
                id="location" formControlName="location"
                [class.is-invalid]="location.invalid && location.touched">
            </div>
            <div class="form-group">
              <label class="input-title" for="brand">Brand</label>
              <select formControlName="brand"  id="brand" class="form-control" placeholder="Choose Brand">
                <option [class.is-invalid]="brand.invalid && brand.touched" *ngFor="let br of brands"
                  value="{{br._id}}">
                  {{br.name}}
                </option>
              </select>
            </div>
	    <div class="form-group">
              <label class="input-title" for="cblock">Content Block</label>
              <select formControlName="cblock" id="cblock" class="form-control" placeholder="Choose ContentBlock">
                <option [class.is-invalid]="cblock.invalid && cblock.touched" *ngFor="let cb of cblocks"
                  value="{{cb._id}}">
                  {{cb.title}}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="input-title" for="image">Image</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Image" type="file" formControlName="image" #img
                  (change)="loadImage(img.files)" class="form-control input-upload-file" name="image" id="image"
                  [class.is-invalid]="image.invalid && image.touched" accept="image/jpeg, image/png, image/jpg">
                <div class="preview-image__container">
                  <img class="preview-image__image" [src]="imageUrl" alt="preview of image" *ngIf="imageUrl">
                </div>
              </div>
              <small *ngIf="image.touched && !imageUrl" class="text-danger">
                Image is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[0]">{{errorMessages[0]}}</small>
            </div>
            <section class="carousel slide pt-2 pb-4" data-ride="carousel" data-interval="false" id="postsCarousel">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 p-0">
                    <div class="upload-btn-wrapper">
                      <button type="button" class="btn btn-outline-secondary float-left">
                        <fa-icon icon="plus"></fa-icon> Extra Images
                      </button>
                      <input type="file" #addImages name="addImages" accept="image/jpeg, image/png, image/jpg"
                        (change)="loadAdditionalImages($event, addImages.files)" multiple />
                    </div>
                    <button type="button" class="btn btn-outline-secondary float-right"
                      [ngClass]="{'disabled': totalImagesRows.length == 1}" (click)="showNextImages()">
                      <fa-icon icon="chevron-right"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-outline-secondary float-right mr-2"
                      [ngClass]="{'disabled': totalImagesRows.length == 1}" (click)="showPrevImages()">
                      <fa-icon icon="chevron-left"></fa-icon>
                    </button>
                  </div>
                </div>
                <small class="text-danger" *ngIf="errorMessages[1]">{{errorMessages[1]}}</small>
              </div>
              <div class="container-fluid carousel-inner pl-0 pr-0 pb-0 pt-4">
                <div *ngFor="let row of totalImagesRows; let i = index" class="row row-equal carousel-item m-0"
                  [ngClass]="{'active': i == currentImageRow}">
                  <div class="col-md-4" *ngFor="let image of additionalImagesUrl | slice:3*i:(3*i)+3; let i2 = index"
                    [ngClass]="{'pl-lg-0': i2 == 0, 'pr-lg-0': i2 == 2}">
                    <div class="card">
                      <a class="text-right pr-1 cursor" (click)="deleteSelectedImage(image)">
                        <fa-icon icon="times"></fa-icon>
                      </a>
                      <div class="card-img-top-200">
                        <img class="img-fluid" src="{{image}}" alt="...">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="form-group">
              <label class="input-title" for="video">Video</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Video" type="file" formControlName="video" #video
                  (change)="loadVideo(video.files)" accept="video/mp4, video/x-m4v, video/*"
                  class="form-control input-upload-file" name="video" id="video"
                  [class.is-invalid]="video.invalid && video.touched">
                <div class="preview-image__container">
                  <video class="preview-image__image" *ngIf="videoUrl" controls>
                    <source [src]="videoUrl">
                  </video>
                </div>
              </div>
            </div>
            <button [disabled]="loading || createEventForm.invalid || !imageUrl" class="glosho-button btn"
              type="submit">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{btnTitle}}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
