import { environment } from '../../environments/environment';

export class APIConstants {
    public static baseURL = environment.apiUrl;
    public static getCategories = `${APIConstants.baseURL}/api/categories`;
    public static createCategory = `${APIConstants.baseURL}/api/categories`;
    public static getBrands = `${APIConstants.baseURL}/api/brands`;
    public static getUsers = `${APIConstants.baseURL}/api/users`;
    public static createBrand = `${APIConstants.baseURL}/api/brands`;
    public static login = `${APIConstants.baseURL}/api/users/login`;
    public static logout = `${APIConstants.baseURL}/api/users/logout`;
    public static getAccountImage = `${APIConstants.baseURL}/api/me/account/image`;
    public static getContentBlocks = `${APIConstants.baseURL}/api/content-blocks`;
    public static getContentBlockTypes = `${APIConstants.baseURL}/api/content-blocks/types`;
    public static getProducts = `${APIConstants.baseURL}/api/products`;
    public static getStores = `${APIConstants.baseURL}/api/stores`;
    public static getEvents = `${APIConstants.baseURL}/api/events`;
    public static createEvent = `${APIConstants.baseURL}/api/events`;
    public static getReportsBrandAmounts = `${APIConstants.baseURL}/api/report/brandAmounts`;
    public static getBrand(id: string): string {return `${APIConstants.baseURL}/api/brands/${id}`; }
    public static getCategory(id: string): string { return `${APIConstants.baseURL}/api/categories/${id}`; }
    public static getContentBlock(id: string): string { return `${APIConstants.baseURL}/api/content-blocks/${id}`; }
    public static getContentBlockImage(id: string): string { return `${APIConstants.baseURL}/api/content-blocks/${id}/image`; }
    public static updateEvent(id: string): string { return `${APIConstants.baseURL}/api/events/${id}`; }
    public static deleteEvent(id: string): string { return `${APIConstants.baseURL}/api/events/${id}`; }
    public static getProduct(id: string): string { return `${APIConstants.baseURL}/api/products/${id}`; }
    public static getStore(id: string): string {return `${APIConstants.baseURL}/api/stores/${id}`; }
    public static activateCategory(id: string): string {return `${APIConstants.baseURL}/api/categories/${id}/activate`; }
    public static deactivateCategory(id: string): string {return `${APIConstants.baseURL}/api/categories/${id}/deactivate`; }
    public static activateBrand(id: string): string {return `${APIConstants.baseURL}/api/brands/${id}/activate`; }
    public static deactivateBrand(id: string): string {return `${APIConstants.baseURL}/api/brands/${id}/deactivate`; }
    public static activateContentBlock(id: string): string {return `${APIConstants.baseURL}/api/content-blocks/${id}/activate`; }
    public static deactivateContentBlock(id: string): string {return `${APIConstants.baseURL}/api/content-blocks/${id}/deactivate`; }
    public static activateProduct(id: string): string {return `${APIConstants.baseURL}/api/products/${id}/activate`; }
    public static deactivateProduct(id: string): string {return `${APIConstants.baseURL}/api/products/${id}/deactivate`; }
    public static activateStore(id: string): string {return `${APIConstants.baseURL}/api/stores/${id}/activate`; }
    public static deactivateStore(id: string): string {return `${APIConstants.baseURL}/api/stores/${id}/deactivate`; }
}
