import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { NgSelectModule } from '@ng-select/ng-select';
import {AgmCoreModule} from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';

import { HttpService, CookiesService, EncryptionDecryptionService, AuthenticationService,
         FileService, CategoryService, BrandService, LoginService, AccountService, ConfirmDialogService,
         ProductService, EventService } from './services';

import { GlobalErrorHandler, GlobalVariables } from './helpers';
import {environment} from '../environments/environment';

import { UsersComponent } from './components/admin-panel/users/users.component';
import { CategoriesComponent } from './components/admin-panel/categories/categories.component';
import { NavBarComponent } from './components/admin-panel/nav-bar/nav-bar.component';
import { SideNavComponent } from './components/admin-panel/side-nav/side-nav.component';
import { CreateEditCategoryComponent } from './components/admin-panel/create-edit-category/create-edit-category.component';
import { CreateEditBrandComponent } from './components/admin-panel/create-edit-brand/create-edit-brand.component';
import { BrandsComponent } from './components/admin-panel/brands/brands.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ContentBlocksComponent } from './components/admin-panel/content-blocks/content-blocks.component';
import { ProductsComponent } from './components/admin-panel/products/products.component';
import { ProductsListComponent } from './components/admin-panel/products-list/products-list.component';
import { ModalProductsListComponent } from './components/admin-panel/modal-products-list/modal-products-list.component';
import { CreateEditContentBlockComponent } from './components/admin-panel/create-edit-content-block/create-edit-content-block.component';
import { CreateEditProductComponent } from './components/admin-panel/create-edit-product/create-edit-product.component';
import { CreateEditEventComponent } from './components/admin-panel/create-edit-event/create-edit-event.component';

import {library} from '@fortawesome/fontawesome-svg-core';
import {faEllipsisH, faPlus, faChevronLeft, faChevronRight, faTimes, faBars} from '@fortawesome/free-solid-svg-icons';
import { EventsComponent } from './components/admin-panel/events/events.component';
import { StoresComponent } from './components/admin-panel/stores/stores.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CreateEditStoreComponent } from './components/admin-panel/create-edit-store/create-edit-store.component';
import { ReportsComponent } from './components/admin-panel/reports/reports.component';
import { ReportsModalComponent } from './components/admin-panel/reports-modal/reports-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    CategoriesComponent,
    NavBarComponent,
    SideNavComponent,
    CreateEditCategoryComponent,
    BrandsComponent,
    CreateEditBrandComponent,
    LoginComponent,
    AdminPanelComponent,
    PageNotFoundComponent,
    ConfirmDialogComponent,
    ContentBlocksComponent,
    ProductsComponent,
    ProductsListComponent,
    ModalProductsListComponent,
    CreateEditContentBlockComponent,
    CreateEditProductComponent,
    StoresComponent,
    LoaderComponent,
    UsersComponent,
    CreateEditStoreComponent,
    ReportsComponent,
    ReportsModalComponent,
    EventsComponent,
    CreateEditEventComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      onActivateTick: true
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    CookieService, HttpService, CookiesService, EncryptionDecryptionService,
    AuthenticationService, FileService, CategoryService, BrandService, LoginService,
    AccountService, GlobalVariables, ConfirmDialogService, ProductService, EventService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(faEllipsisH, faPlus, faChevronLeft, faChevronRight, faTimes, faBars);
  }
}
