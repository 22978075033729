import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../../models';

declare var $: any;

@Component({
  selector: 'app-modal-products-list',
  templateUrl: './modal-products-list.component.html',
  styleUrls: ['./modal-products-list.component.scss']
})
export class ModalProductsListComponent implements OnInit {
  @Output() productsListChanged = new EventEmitter();
  @Input() selectedProducts: Product[] = [];
  public isSelectMode = true;
  private productModal: any;

  constructor() { }

  ngOnInit() {
    this.productModal = $('#productModal');
  }

  getSelectedProductsList(products) {
    this.selectedProducts = products;
  }

  selectProductsList() {
    this.productModal.modal('hide');
    this.productsListChanged.emit(this.selectedProducts);
  }
}
