import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../AuthenticationService/Authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  HttpGet<T>(url: string, params: HttpParams, isBlob?: boolean): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(),
      params,
      responseType: (isBlob ? 'blob' : 'json') as 'json'
    };

    return this.http.get<T>(url, httpOptions);
  }

  HttpPost<T>(url: string, data: any, params: HttpParams, isMultiPart: boolean = false): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(isMultiPart),
      params
    };

    return this.http.post<T>(url, data, httpOptions);
  }

  HttpPut<T>(url: string, data: any, params: HttpParams, isMultiPart: boolean = false): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(isMultiPart),
      params
    };

    return this.http.put<T>(url, data, httpOptions);
  }

  HttpPatch<T>(url: string, data: any, params: HttpParams, isMultiPart: boolean = false): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(isMultiPart),
      params
    };

    return this.http.patch<T>(url, data, httpOptions);
  }

  HttpDelete<T>(url: string, params: HttpParams): Observable<T> {
    const httpOptions = {
      headers: this.getHeaders(),
      params
    };

    return this.http.delete<T>(url, httpOptions);
  }

  private getHeaders(isMultiPart: boolean = false): HttpHeaders {
    let headers = new HttpHeaders();

    if (!isMultiPart) {
      headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    }

    const token = this.authenticationService.getToken();
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }

    return headers;
  }
}
