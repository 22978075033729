export class EventsList {
  constructor(
    public events: Event[],
    public totalDocs: number,
    public pageSize: number,
    public page: number,
    public totalPages: number,
    public pagingCounter: number,
    public hasPrevPage: boolean,
    public hasNextPage: boolean,
    public prevPage: boolean,
    public nextPage: boolean
  ) { }
}

export class Event {
  constructor(
    // tslint:disable-next-line:variable-name
    public _id: string,
    public name: string,
    public description: string,
    public date: DateRange,
    public image: string,
    public dateNote?: string,
    public location?: string,
    public additionalImages?: string[],
    public video?: string,
    public brand?: EventBrand,
    public cblock?: EventContentBlock
  ) { }
}

export class DateRange {
  constructor(
    public from: Date,
    public to?: Date
  ) { }
}

class EventBrand {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id?: string,
        public name?: string
    ) { }
}

class EventContentBlock {
    constructor(
        // tslint:disable-next-line:variable-name
        public _id?: string,
        public title?: string
    ) { }
}
