import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CategoriesComponent } from './components/admin-panel/categories/categories.component';
import { BrandsComponent } from './components/admin-panel/brands/brands.component';
import { LoginComponent } from './components/login/login.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ContentBlocksComponent } from './components/admin-panel/content-blocks/content-blocks.component';
import { ProductsComponent } from './components/admin-panel/products/products.component';
import { CreateEditContentBlockComponent } from './components/admin-panel/create-edit-content-block/create-edit-content-block.component';
import { StoresComponent } from './components/admin-panel/stores/stores.component';
import {ReportsComponent} from './components/admin-panel/reports/reports.component';
import { EventsComponent } from './components/admin-panel/events/events.component';
import { UsersComponent } from './components/admin-panel/users/users.component';

const routes: Routes = [
  {
    path: 'adminPanel', component: AdminPanelComponent,
    children: [
       { path: '', redirectTo: '/adminPanel/brands', pathMatch: 'full'},
       { path: 'categories', component: CategoriesComponent },
       { path: 'brands', component: BrandsComponent },
       { path: 'content-blocks', component: ContentBlocksComponent },
       { path: 'products', component: ProductsComponent },
       { path: 'create-edit-content-block', component: CreateEditContentBlockComponent },
       { path: 'stores', component: StoresComponent},
       { path: 'reports', component: ReportsComponent },
       { path: 'events', component: EventsComponent},
       { path: 'users', component: UsersComponent }
      ]
  },
    { path: 'login', component: LoginComponent },
    { path: '', redirectTo: '/adminPanel/brands', pathMatch: 'full'},
    { path: '404', component: PageNotFoundComponent},
    { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
