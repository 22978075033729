<div class="row mt-4">
  <div class="col text-right">
    <button type="button" class="glosho-button btn btn-lg" (click)="showCreateEvent()">New Event</button>
  </div>
</div>
<div class="row no-gutters justify-content-end">
  <div class="col-sm-3">
    <label for="event-search"></label>
    <input id="event-search" type="text" class="form-control" name="desc" #desc
      (keyup.enter)="currentPage = 1; filterEvents($event, currentPage,  desc.value)" placeholder="Search..">
  </div>
</div>

<div class="table-responsive-md w-100">
  <table class="table table-bordered color-white">
    <thead>
      <tr>
        <th>Image</th>
        <th class="hide">Name</th>
        <th class="hide">Time & Place</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of events">
        <td class="table__image"><img src="{{event.image}}" alt="image of event" class="rounded imageStyle">
        </td>
        <td class="align-middle hide">{{event.name}}</td>
        <td class="align-middle hide">
          <div>
            {{formatDate(event.date.from)}}
            {{(event.date.to) ? ' - ' + formatDate(event.date.to) : ''}}
          </div>
          <div>
            {{event.location}}
          </div>
        </td>
        <td class="align-middle">
          <div class="dropdown dropleft">
            <button class="btn btn-light btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <fa-icon icon="ellipsis-h"></fa-icon>
            </button>
            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
              <button class="btn btn-block m-0 btn-warning" (click)="showEditEvent(event)">
                <span class="text-color-white">Edit</span>
              </button>
              <button class="btn btn-block m-0 btn-danger" (click)="showConfirmDialog(event._id)">
                <span>Delete</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<nav aria-label="..." class="table-responsive">
  <ul class="pagination flex-wrap justify-content-end">
    <li class="page-item" [ngClass]="{'disabled': currentPage == 1 }">
      <a class="page-link cursor"
         (click)="currentPage = 1; filterEvents($event, currentPage, desc.value)">
        First
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !eventsList || (eventsList && !eventsList.hasPrevPage)}">
      <a class="page-link cursor"
        (click)="currentPage = currentPage - 1; filterEvents($event, currentPage, desc.value)">
        Prev
      </a>
    </li>
    <li *ngFor="let page of findPagesRollingWindow(currentPage, this.totalPages); let i = index" class="page-item" [ngClass]="{'active': page == currentPage}">
      <a class="page-link cursor" (click)="currentPage = page; filterEvents($event, currentPage, desc.value)">
        {{page}}
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !eventsList || (eventsList && !eventsList.hasNextPage)}">
      <a class="page-link cursor"
        (click)="currentPage = currentPage + 1; filterEvents($event, currentPage, desc.value)">
        Next
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages.length }">
      <a class="page-link cursor"
         (click)="currentPage = totalPages.length;  filterEvents($event, currentPage, desc.value)">
        Last
      </a>
    </li>
  </ul>
</nav>
<app-create-edit-event [event]="event" (createdEvent)="filterEvents($event, currentPage, desc.value)"></app-create-edit-event>
<app-confirm-dialog></app-confirm-dialog>
