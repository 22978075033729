import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';
import { Brand, BrandsList } from '../../models/Brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private httpService: HttpService) { }

  getBrands(page?: number, pageSize?: number, desc?: string) {
    let params = new HttpParams();
    if (page) { params = params.append('page', String(page)); }
    if (pageSize) { params = params.append('pageSize', String(pageSize)); }
    if (desc) { params = params.append('desc', desc); }
    return this.httpService.HttpGet<BrandsList>(APIConstants.getBrands, params);
  }

  deleteBrand(id: string) {
    return this.httpService.HttpDelete<Brand>(APIConstants.getBrand(id), null);
  }

  createBrand(name: string, displayName: string,
              description: string, logo: any, image: any, storefrontImage: any, video: any, display: string) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('displayName', displayName);
    formData.append('description', description);
    formData.append('logo', logo, logo.name);
    formData.append('image', image, image.name);
    formData.append('storefrontImage', storefrontImage, storefrontImage.name);
    formData.append('video', video, video.name);
    if (display) { formData.append('display', display); }
    return this.httpService.HttpPost<FormData>(APIConstants.createBrand, formData, null, true);
  }

  updateBrand(id: string, name: string, displayName: string,
              description: string, logo: any, image: any, storefrontImage: any, video: any, display: string) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('displayName', displayName);
    formData.append('description', description);
    if (logo) { formData.append('logo', logo, logo.name); }
    if (image) { formData.append('image', image, image.name); }
    if (storefrontImage) { formData.append('storefrontImage', storefrontImage, storefrontImage.name); }
    if (video) { formData.append('video', video, video.name); }
    formData.append('display', display);
    return this.httpService.HttpPut<FormData>(APIConstants.getBrand(id), formData, null, true);
  }

  activateDeactivateBrand(id: string, isActivated: boolean) {
    if (isActivated === true) {
      return this.httpService.HttpPost(APIConstants.activateBrand(id), null, null);
    }
    return this.httpService.HttpPost(APIConstants.deactivateBrand(id), null, null);
  }
}
