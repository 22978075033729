import {Component, OnInit} from '@angular/core';
import { Brand, BrandsList } from '../../../models/Brand';
import { BrandService, ConfirmDialogService } from '../../../services';
import { GlobalVariables, RollingPageWindow } from '../../../helpers';

declare var $: any;

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  brandsList: BrandsList;
  brands: Brand[];
  brand: Brand;
  currentPage = 1;
  totalPages: number[];

  public findPagesRollingWindow: (currentPage: number, totalPages: Array<number>) => (number[]);

  constructor(private brandService: BrandService,
              private globalVariables: GlobalVariables,
              private confirmDialogService: ConfirmDialogService) {

    this.findPagesRollingWindow = RollingPageWindow.findPagesRollingWindow;
  }

  ngOnInit(): void {
    this.globalVariables.navBarTitle = 'Brands';
    this.getBrands(this.currentPage);
  }

  getBrands(page: number, desc?: string) {
    this.brandService.getBrands(page, 10, desc)
        .subscribe((value) => {
          this.brandsList = value;
          this.totalPages = new Array<number>(value.totalPages);
          this.brands = value.brands;
        });
  }

  filterBrands($event, page, desc) {
    console.log(page, desc);
    this.getBrands(page, desc);
  }

  showConfirmDialog(id: string) {
    this.confirmDialogService.confirmThis('Confirm', 'Are you sure that you want to delete this brand?', 'Yes', 'No',
        () => {
          this.deleteBrand(id);
        }, () => {
        });
  }

  deleteBrand(id: string) {
    this.brandService.deleteBrand(id).subscribe((value) => {
      this.brands = this.brands.filter((brand) => {
        return brand._id !== id;
      });
    });
  }

  showCreateBrand() {
    this.brand = new Brand('', '', '', '', null, null, null, null, true, 'XY');
    $('#brandModal').modal('show');
  }

  showEditBrand(brand: Brand) {
    this.brand = brand;
    $('#brandModal').modal('show');
  }

  activateDeactivateBrand(brand) {
    const isActivated = !brand.isActivated;
    this.brandService.activateDeactivateBrand(brand._id, isActivated).subscribe(
        value => {
          brand.isActivated = isActivated;
        }
    );
  }
}
