import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CategoryService, FileService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { Category } from '../../../models';
import { finalize } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-create-edit-category',
  templateUrl: './create-edit-category.component.html',
  styleUrls: ['./create-edit-category.component.scss']
})
export class CreateEditCategoryComponent implements OnInit , OnDestroy {
  @Output() createdCategory = new EventEmitter();
  @Input() category: Category;

  private categoryModal: any;
  private portraitImageFile: File;
  private landscapeImageFile: File;
  public portraitImageUrl: any;
  public landscapeImageUrl: any;
  public errorMessages = ['', ''];
  public displayOptions = [{name: 'X'}, {name: 'Y'}, {name: 'XY'}];
  public title = 'Create Category';
  public btnTitle = 'Create';
  public loading = false;

  createCategoryForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: null,
    portraitImage: '',
    landscapeImage: '',
    display: 'XY'
  });

  constructor(private categoryService: CategoryService, private fileService: FileService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.categoryModal = $('#categoryModal');
    this.categoryModal.on('shown.bs.modal', () => {
      this.initCategoryModel();
    });

    this.categoryModal.on('hidden.bs.modal', () => {
      this.initCategoryModel(true);
    });
  }

  ngOnDestroy(): void {
    if (this.categoryModal) {
      this.categoryModal.off('shown.bs.modal hidden.bs.modal');
    }
  }

  initCategoryModel(forceClear: boolean = false) {
    this.createCategoryForm.reset();
    this.portraitImageFile = null;
    this.landscapeImageFile = null;
    if (forceClear) {
      this.createCategoryForm.get('name').setValue('');
      this.createCategoryForm.get('description').setValue('');
      this.createCategoryForm.get('display').setValue('');
      this.portraitImageUrl = null;
      this.landscapeImageUrl = null;
    } else {
      this.createCategoryForm.get('name').setValue(this.category.name);
      this.createCategoryForm.get('description').setValue(this.category.description);
      this.createCategoryForm.get('display').setValue(this.category.display);
      this.portraitImageUrl = this.category.portraitImage;
      this.landscapeImageUrl = this.category.landscapeImage;
    }
    this.title = this.category._id === '' ? 'Create Category' : 'Update Category';
    this.btnTitle = this.category._id === '' ? 'Create' : 'Update';
  }

  get name() {
    return this.createCategoryForm.get('name');
  }
  get description() {
    return this.createCategoryForm.get('description');
  }
  get portraitImage() {
    return this.createCategoryForm.get('portraitImage');
  }
  get landscapeImage() {
    return this.createCategoryForm.get('landscapeImage');
  }
  get display() {
    return this.createCategoryForm.get('display');
  }

  onSubmitCategory() {
    if (this.category._id === '') {
      this.createCategory();
    } else {
      this.updateCategory();
    }
  }

  createCategory() {
    this.loading = true;
    this.categoryService.createCategory(this.name.value, this.description.value,
      this.portraitImageFile, this.landscapeImageFile, this.display.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.categoryModal.modal('hide');
          this.createdCategory.emit();
        }
      );
  }

  updateCategory() {
    this.loading = true;
    this.categoryService.updateCategory(this.category._id, this.name.value, this.description.value,
      this.portraitImageFile, this.landscapeImageFile, this.display.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.categoryModal.modal('hide');
          this.createdCategory.emit();
        }
      );
  }

  previewPortraitImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.portraitImageFile = files[0];
        this.portraitImageUrl = value;
        this.errorMessages[0] = '';
      },
      error => {
        this.errorMessages[0] = error.message;
        this.portraitImageFile = null;
        this.portraitImageUrl = null;
      }
    );
  }

  previewLandscapeImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.landscapeImageFile = files[0];
        this.landscapeImageUrl = value;
        this.errorMessages[1] = '';
      },
      error => {
        this.errorMessages[1] = error.message;
        this.landscapeImageFile = null;
        this.landscapeImageUrl = null;
      }
    );
  }
}
