export class HttpErrorMessages {
    // tslint:disable-next-line:variable-name
    public static convertedMessages = {
        INVALID_FILES : 'Your files are invalid.',
        UNKNOWN_ACCESSOR : 'You are not logged in.',
        ACCESS_DENIED: 'You do not have the necessary permissions for this request.',
        EMAIL_NOT_FOUND: 'Your email was not found.',
        FAILED: 'The request failed.',
        INVALID_ID: 'The request failed.',
        BRAND_NOT_FOUND: 'The brand was not found.',
        PRODUCTS_EXIST_CONFLICT: 'There are still products related to this brand.',
        CATEGORY_NOT_FOUND: 'The category was not found.',
        INVALID_PRODUCTS: 'Some products are invalid.',
        INVALID_PRODUCT_LIMIT: 'The product limit is invalid.',
        CONTENT_BLOCK_NOT_FOUND: 'The content block was not found.',
        IMAGE_NOT_FOUND: 'The image could not be fetched.',
        CONTENT_BLOCK_TYPE_NOT_FOUND: 'The content block type was not found.',
        PRODUCTS_NOT_FOUND: 'Some products were not found.',
        INVALID_BRAND: 'The brand is invalid.',
        INVALID_CATEGORY: 'The category is invalid.',
        INVALID_DESC: 'The description is invalid.',
        INVALID_PAGE: 'The page is invalid.'
    };

    public static getUserFriendlyMessage(message: string): string {
        const displayMessage = HttpErrorMessages.convertedMessages[message];
        return displayMessage || 'The request failed.';
    }
}
