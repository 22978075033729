import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BrandService, FileService } from '../../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { Brand } from '../../../models';
import { finalize } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-create-edit-brand',
  templateUrl: './create-edit-brand.component.html',
  styleUrls: ['./create-edit-brand.component.scss']
})
export class CreateEditBrandComponent implements OnInit, OnDestroy {

  @Output() createdBrand = new EventEmitter();
  @Input() brand: Brand;

  private brandModal: any;
  private logoFile: File;
  private imageFile: File;
  private storefrontImageFile: File;
  private videoFile: File;
  public logoUrl: any;
  public imageUrl: any;
  public storefrontImageUrl: any;
  public videoUrl: any;
  public errorMessages = ['', '', '', ''];
  public displayOptions = [{name: 'X'}, {name: 'Y'}, {name: 'XY'}];
  public title = 'Create Brand';
  public btnTitle = 'Create';
  public loading = false;

  createBrandForm = this.formBuilder.group({
    name: ['', Validators.required],
    displayName: ['', Validators.required],
    description: ['', Validators.required],
    logo: '',
    image: '',
    storefrontImage: '',
    video: '',
    display: 'XY'
  });

  constructor(private brandService: BrandService, private fileService: FileService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.brandModal = $('#brandModal');
    this.brandModal.on('shown.bs.modal', () => {
      this.initBrandModel();
    });

    this.brandModal.on('hidden.bs.modal', () => {
      this.initBrandModel(true);
    });
  }

  ngOnDestroy(): void {
    if (this.brandModal) {
      this.brandModal.off('shown.bs.modal hidden.bs.modal');
    }
  }

  initBrandModel(forceClear: boolean = false) {
    this.createBrandForm.reset();
    this.logoFile = null;
    this.imageFile = null;
    this.storefrontImageFile = null;
    this.videoFile = null;
    if (forceClear) {
      this.createBrandForm.get('name').setValue('');
      this.createBrandForm.get('displayName').setValue('');
      this.createBrandForm.get('description').setValue('');
      this.createBrandForm.get('display').setValue('');
      this.logoUrl = null;
      this.imageUrl = null;
      this.storefrontImageUrl = null;
      this.videoUrl = null;
    } else {
      this.createBrandForm.get('name').setValue(this.brand.name);
      this.createBrandForm.get('displayName').setValue(this.brand.displayName);
      this.createBrandForm.get('description').setValue(this.brand.description);
      this.createBrandForm.get('display').setValue(this.brand.display);
      this.logoUrl = this.brand.logo;
      this.imageUrl = this.brand.image;
      this.storefrontImageUrl = this.brand.storefrontImage;
      this.videoUrl = this.brand.video;
    }
    this.title = this.brand._id === '' ? 'Create Brand' : 'Update Brand';
    this.btnTitle = this.brand._id === '' ? 'Create' : 'Update';
  }

  get name() {
    return this.createBrandForm.get('name');
  }
  get displayName() {
    return this.createBrandForm.get('displayName');
  }
  get description() {
    return this.createBrandForm.get('description');
  }
  get logo() {
    return this.createBrandForm.get('logo');
  }
  get image() {
    return this.createBrandForm.get('image');
  }
  get storefrontImage() {
    return this.createBrandForm.get('storefrontImage');
  }
  get video() {
    return this.createBrandForm.get('video');
  }
  get display() {
    return this.createBrandForm.get('display');
  }

  onSubmitBrand() {
    if (this.brand._id === '') {
      this.createBrand();
    } else {
      this.updateBrand();
    }
  }

  createBrand() {
    this.loading = true;
    this.brandService.createBrand(this.name.value, this.displayName.value, this.description.value,
      this.logoFile, this.imageFile, this.storefrontImageFile, this.videoFile, this.display.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.brandModal.modal('hide');
          this.createdBrand.emit();
        }
      );
  }

  updateBrand() {
    this.loading = true;
    this.brandService.updateBrand(this.brand._id, this.name.value, this.displayName.value, this.description.value,
      this.logoFile, this.imageFile, this.storefrontImageFile, this.videoFile, this.display.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.brandModal.modal('hide');
          this.createdBrand.emit();
        }
      );
  }

  loadLogoImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.logoFile = files[0];
        this.logoUrl = value;
        this.errorMessages[0] = '';
      },
      error => {
        this.errorMessages[0] = error.message;
        this.logoFile = null;
        this.logoUrl = null;
      }
    );
  }

  loadImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.imageFile = files[0];
        this.imageUrl = value;
        this.errorMessages[1] = '';
      },
      error => {
        this.errorMessages[1] = error.message;
        this.imageFile = null;
        this.imageUrl = null;
      }
    );
  }

  loadStoreFrontImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.storefrontImageFile = files[0];
        this.storefrontImageUrl = value;
        this.errorMessages[2] = '';
      },
      error => {
        this.errorMessages[2] = error.message;
        this.storefrontImageFile = null;
        this.storefrontImageUrl = null;
      }
    );
  }

  loadVideo(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.videoFile = files[0];
        this.videoUrl = value;
        this.errorMessages[3] = '';
      },
      error => {
        this.errorMessages[3] = error.message;
        this.videoFile = null;
        this.videoUrl = null;
      }
    );
  }
}
