import { Component, OnInit, Input } from '@angular/core';
import {BrandAmount} from '../../../models';

declare const $;

@Component({
  selector: 'app-reports-modal',
  templateUrl: './reports-modal.component.html',
  styleUrls: ['./reports-modal.component.scss']
})
export class ReportsModalComponent implements OnInit {

  @Input() report: BrandAmount;
  public title = 'Brand Amounts Report';

  constructor() {
  }

  ngOnInit() {

  }

}

