<div class="row mt-4">
  <div class="col text-right">
    <button type="button" class="glosho-button btn btn-lg" (click)="showCreateStore()">New Store</button>
  </div>
</div>
<div class="row no-gutters justify-content-end">
  <div class="col-sm-3">
    <div class="form-group">
      <label for="brand" class="font-weight-bold">Brand</label>
      <select id="brand" class="form-control" name="brand" #brand
              (change)="currentPage = 1; filterStores($event, currentPage, brand.value)">
        <option *ngFor="let brand of brands" value="{{brand._id}}">
          {{brand.name}}
        </option>
      </select>
    </div>
  </div>
</div>

<div class="row margin-top__-40px">
<div class="table-responsive-md w-100">
  <table class="table table-bordered color-white">
    <thead>
    <tr>
      <th>Name</th>
      <th>Street Address</th>
      <th class="hide">Post Code</th>
      <th class="hide">City</th>
      <th class="hide">Country</th>
      <th class="hide">Phone</th>
      <th class="hide">Hours</th>
      <th class="hide">Status</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let store of stores">
      <td class="align-middle">{{store.name}}</td>
      <td class="align-middle">{{store.streetAddress}}</td>
      <td class="align-middle hide">{{store.postcode}}</td>
      <td class="align-middle hide">{{store.city}}</td>
      <td class="align-middle hide">{{store.country}}</td>
      <td class="align-middle hide">{{store.phone}}</td>
      <td class="align-middle hide">{{store.hours}}</td>
      <td class="align-middle hide">{{store.isActivated ? 'Activated' : 'Deactivated'}}</td>
      <td class="align-middle">
        <div class="dropdown dropleft">
          <button class="btn btn-light btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
            <fa-icon icon="ellipsis-h"></fa-icon>
          </button>
          <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
            <button class="btn btn-block btn-warning" (click)="showEditStore(store)">
              <span class="text-color-white">Edit</span>
            </button>
            <button class="btn btn-block m-0 btn-danger" (click)="showConfirmDialog(store._id)">
              <span>Delete</span>
            </button>
            <button class="btn btn-block m-0 btn-primary" (click)="activateDeactivateStore(store)">
              <span>{{store.isActivated ? 'Deactivate' : 'Activate'}}</span>
            </button>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<nav aria-label="..." class="table-responsive">
  <ul class="pagination flex-wrap justify-content-end">
    <li class="page-item" [ngClass]="{'disabled': currentPage == 1 }">
      <a class="page-link cursor"
         (click)="currentPage = 1; filterStores($event, currentPage, brand.value)">
        First
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !storesList || (storesList && !storesList.hasPrevPage)}">
      <a class="page-link cursor"
         (click)="currentPage = currentPage - 1; filterStores($event, currentPage, brand.value)">
        Prev
      </a>
    </li>
    <li *ngFor="let page of findPagesRollingWindow(currentPage, this.totalPages); let i = index;" class="page-item" [ngClass]="{'active': page === currentPage}">
      <a class="page-link cursor"  (click)="currentPage = page; filterStores($event, currentPage, brand.value)">
        {{page}}
      </a>
    <li class="page-item" [ngClass]="{'disabled': !storesList || (storesList && !storesList.hasNextPage)}">
      <a class="page-link cursor"
         (click)="currentPage = currentPage + 1; filterStores($event, currentPage, brand.value)">
        Next
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages.length }">
      <a class="page-link cursor"
         (click)="currentPage = totalPages.length; filterStores($event, currentPage, brand.value)">
        Last
      </a>
    </li>
  </ul>
</nav>
</div>
<app-create-edit-store [store]="store" (createdStore)="filterStores($event, currentPage, brand.value)"></app-create-edit-store>
<app-confirm-dialog></app-confirm-dialog>
