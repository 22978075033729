export class BrandsList {
  constructor(
    public brands: Brand[],
    public totalDocs: number,
    public pageSize: number,
    public page: number,
    public totalPages: number,
    public pagingCounter: number,
    public hasPrevPage: boolean,
    public hasNextPage: boolean,
    public prevPage: boolean,
    public nextPage: boolean
  ) { }
}

export class Brand {
  constructor(
    // tslint:disable-next-line:variable-name
    public _id: string,
    public name: string,
    public displayName: string,
    public description: string,
    public logo: any,
    public image: any,
    public storefrontImage: any,
    public video: any,
    public isActivated: boolean,
    public display?: string
  ) { }
}
