import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {

  public sidebarCollapsed = true;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    // redirect to login if user is not logged in
    if (!this.authenticationService.getToken()) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
  }

  sidebarToggled() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

}
