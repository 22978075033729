import { Injectable } from '@angular/core';
import {HttpService} from '../HttpService/Http.service';
import {HttpParams} from '@angular/common/http';
import {APIConstants} from '../../helpers';
import {Store, StoresList} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private httpService: HttpService) { }

  getStores(page: number, pageSize?: number, brand?: string, populateBrand?: boolean) {
    let params = new HttpParams().set('page', String(page));
    if (pageSize) { params = params.append('pageSize', String(pageSize)); }
    if (brand) { params = params.append('brand', brand); }
    if (populateBrand) { params = params.append('populateBrand', String(populateBrand)); }
    return this.httpService.HttpGet<StoresList>(APIConstants.getStores, params);
  }

  deleteStore(id: string) {
    return this.httpService.HttpDelete<Store>(APIConstants.getStore(id), null);
  }

  createStore(name: string, streetAddress: string, postcode: string, city: string,
              country: string, hours: string, brands: any, additionalStreetAddress?: string, location?: number[], phone?: string) {
    const data: any = {
      name,
      streetAddress,
      postcode,
      city,
      country,
      hours,
      brands
    };
    if (additionalStreetAddress) { data.additionalStreetAddress = additionalStreetAddress; }
    if (location) { data.location = location; }
    if (phone) { data.phone = phone; }
    return this.httpService.HttpPost<FormData>(APIConstants.getStores, data, null, false);
  }

  updateStore(id: string, name: string, streetAddress: string, postcode: string, city: string,
              country: string, hours: string, brands: any, additionalStreetAddress?: string, location?: number[], phone?: string) {
    const data: any = {
      name,
      streetAddress,
      postcode,
      city,
      country,
      hours,
      brands
    };
    if (additionalStreetAddress) { data.additionalStreetAddress = additionalStreetAddress; }
    if (location) { data.location = location; }
    if (phone) { data.phone = phone; }
    return this.httpService.HttpPut(APIConstants.getStore(id), data, null, false);
  }

  activateDeactivateStore(id: string, isActivated: boolean) {
    if (isActivated === true) {
      return this.httpService.HttpPost(APIConstants.activateStore(id), null, null);
    }
    return this.httpService.HttpPost(APIConstants.deactivateStore(id), null, null);
  }
}
