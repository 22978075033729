import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentBlockService, FileService } from '../../../services';
import { GlobalVariables } from '../../../helpers';
import { FormBuilder, Validators } from '@angular/forms';
import { Product, ContentBlock, ContentBlockType } from '../../../models';
import { finalize } from 'rxjs/operators';
import { typeSourceSpan } from '@angular/compiler';

declare var $: any;

@Component({
  selector: 'app-create-edit-content-block',
  templateUrl: './create-edit-content-block.component.html',
  styleUrls: ['./create-edit-content-block.component.scss']
})
export class CreateEditContentBlockComponent implements OnInit {
  private imageFile: File;
  public contentBlock: ContentBlock;
  public selectedProducts: Product[] = [];
  public initialSelectedProducts: Product[] = [];
  public imageUrl: any;
  public errorMessage = '';
  public types: ContentBlockType[] = [];
  public btnTitle = 'Create';
  public loading = false;
  public totalProductsRows = new Array<number>(1);
  public currentProductRow = 0;

  createContentBlockForm = this.formBuilder.group({
    title: ['', Validators.required],
    subTitle: ['', Validators.required],
    contenBlockType: ['', Validators.required],
    image: '',
    productLimit: '',
    displayOrder: ''
  });

  constructor(private contentBlockService: ContentBlockService,
              private fileService: FileService,
              private formBuilder: FormBuilder,
              private globalVariables: GlobalVariables,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    const cBlockIdParam = this.route.snapshot.params.contentBlockId;
    if (cBlockIdParam) {
      this.getContentBlock(cBlockIdParam);
    } else {
      this.contentBlock = new ContentBlock('', '', '', null, [], new ContentBlockType('', '', ''), true);
      this.initContentBlockModel();
    }
  }

  getContentBlockTypes() {
    this.contentBlockService.getContentBlockTypes().subscribe(
      value => {
        this.types = value.cblockTypes;
        const typeValue = this.contentBlock.type._id || (this.types && this.types.length > 0 ?
          this.types[0]._id : '');
        this.createContentBlockForm.get('contenBlockType').setValue(typeValue);
      }
    );
  }

  getContentBlock(id: string) {
    this.contentBlockService.getContentBlock(id).subscribe(
      value => {
        this.contentBlock = value;
        this.initContentBlockModel();
        this.getContentBlockImage(value._id);
      }
    );
  }

  getContentBlockImage(id: string) {
    this.contentBlockService.getContentBlockImage(id)
      .subscribe(image => {
        this.fileService.previewFile([image])
          .subscribe(
            imageUrl => {
              this.imageUrl = imageUrl;
            });
      });
  }

  initContentBlockModel() {
    this.createContentBlockForm.get('title').setValue(this.contentBlock.title);
    this.createContentBlockForm.get('subTitle').setValue(this.contentBlock.subTitle);
    this.createContentBlockForm.get('productLimit').setValue(this.contentBlock.productLimit);
    this.createContentBlockForm.get('displayOrder').setValue(this.contentBlock.displayOrder);
    this.globalVariables.navBarTitle = this.contentBlock._id === '' ? 'Create Content Block' : 'Update Content Block';
    this.btnTitle = this.contentBlock._id === '' ? 'Create' : 'Update';
    this.getSelectedProductsList(this.contentBlock.products);
    this.getContentBlockTypes();
  }

  get title() {
    return this.createContentBlockForm.get('title');
  }
  get subTitle() {
    return this.createContentBlockForm.get('subTitle');
  }
  get contenBlockType() {
    return this.createContentBlockForm.get('contenBlockType');
  }
  get productLimit() {
    return this.createContentBlockForm.get('productLimit');
  }
  get displayOrder() {
    return this.createContentBlockForm.get('displayOrder');
  }
  get image() {
    return this.createContentBlockForm.get('image');
  }

  showProductsList() {
    this.initialSelectedProducts = Object.assign([], this.selectedProducts);
    $('#productModal').modal('show');
  }

  getSelectedProductsList(products: Product[]) {
    this.selectedProducts = products;
    this.calculateTotalProductsRows();
  }

  calculateTotalProductsRows() {
    const numberOfRows = this.selectedProducts.length > 0 ? this.selectedProducts.length / 3 : 1;
    this.totalProductsRows = new Array<number>(Math.ceil(numberOfRows));
    this.currentProductRow = this.currentProductRow > this.totalProductsRows.length - 1 ? this.totalProductsRows.length - 1
      : this.currentProductRow;
  }

  showPrevProducts() {
    this.currentProductRow = this.currentProductRow === 0 ? this.totalProductsRows.length - 1 : this.currentProductRow - 1;
  }

  showNextProducts() {
    this.currentProductRow = this.currentProductRow === this.totalProductsRows.length - 1 ? 0 : this.currentProductRow + 1;
  }

  deleteSelectedProduct(id: string) {
    this.selectedProducts = this.selectedProducts.filter((product) => {
      return product._id !== id;
    });
    this.calculateTotalProductsRows();
  }

  onSubmitContentBlock() {
    if (this.contentBlock._id === '') {
      this.createContentBlock();
    } else {
      this.updateContentBlock();
    }
  }

  getProductsId() {
    return this.selectedProducts.map(({ _id }) => _id);
  }

  createContentBlock() {
    this.loading = true;
    this.contentBlockService.createContentBlock(this.title.value, this.subTitle.value, this.imageFile, this.getProductsId(),
      this.contenBlockType.value, this.productLimit.value, this.displayOrder.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.router.navigate(['/adminPanel/content-blocks']);
        }
      );
  }

  updateContentBlock() {
    this.loading = true;
    this.contentBlockService.updateContentBlock(this.contentBlock._id, this.title.value, this.subTitle.value, this.imageFile,
      this.getProductsId(), this.contenBlockType.value, this.productLimit.value, this.displayOrder.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        value => {
          this.router.navigate(['/adminPanel/content-blocks']);
        }
      );
  }

  loadImage(files) {
    this.fileService.previewFile(files).subscribe(
      value => {
        this.imageFile = files[0];
        this.imageUrl = value;
        this.errorMessage = '';
      },
      error => {
        this.errorMessage = error.message;
        this.imageFile = null;
        this.imageUrl = null;
      }
    );
  }
}
