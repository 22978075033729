<div class="row mt-4">
  <div class="col text-right">
    <button type="button" class="glosho-button btn btn-lg" (click)="showCreateContentBlock()">New content block</button>
  </div>
</div>

<div class="table-responsive-md w-100">
  <table class="table table-bordered color-white">
    <thead>
      <tr>
        <th></th>
        <th class="hide">Title</th>
        <th class="hide">SubTitle</th>
        <th class="hide">Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let contentBlock of contentBlocks">
        <td class="table__image"><img *ngIf="contentBlocksImagesUrl[contentBlock._id]"
            [src]="contentBlocksImagesUrl[contentBlock._id]" alt="avatar of image" class="rounded imageStyle">
        </td>
        <td class="align-middle hide">{{contentBlock.title}}</td>
        <td class="align-middle hide">{{contentBlock.subTitle}}</td>
        <td class="align-middle hide">{{contentBlock.isActivated ? 'Activated' : 'Deactivated'}}</td>
        <td class="align-middle">
          <div class="dropdown dropleft">
            <button class="btn btn-light btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <fa-icon icon="ellipsis-h"></fa-icon>
            </button>
            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
              <button class=" btn btn-block m-0 btn-warning" (click)="showEditContentBlock(contentBlock)">
                <span class="text-color-white">Edit</span>
              </button>
              <button class="btn btn-block m-0 btn-danger" (click)="showConfirmDialog(contentBlock._id)">
                <span>Delete</span>
              </button>
              <button class="btn btn-block m-0 btn-primary" (click)="activateDeactivateBlock(contentBlock)">
                <span>{{contentBlock.isActivated ? 'Deactivate' : 'Activate'}}</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-confirm-dialog></app-confirm-dialog>
