<nav class="navbar navbar-expand-md px-0">
  <div class="header">
    <div class="header__title">
      <button title="toggle sideMenu" type="button" class="btn glosho-button toggle-sidebar mr-2" (click)="sidebarToggleButtonClicked()">
        <fa-icon icon="bars"></fa-icon>
      </button>
      <h1 class="heading-primary">{{globalVariables.navBarTitle}}</h1>
    </div>
    <div class="header__content">
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header__user-avatar" *ngIf="accountImageUrl; else noImageFound" [src]="accountImageUrl"
               alt="user avatar">
          <ng-template #noImageFound>
            <img class="rounded-circle header__user-avatar" src="../../../../assets/images/logo.png" alt="User avatar">
          </ng-template>
          <span>{{firstName}}</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button class="btn btn-danger btn-sm" (click)="logout()">
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
