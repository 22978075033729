import { Injectable } from '@angular/core';
import { HttpService } from '../HttpService/Http.service';
import { AuthenticationService } from '../AuthenticationService/Authentication.service';
import { APIConstants } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

constructor(private httpService: HttpService, private authenticationService: AuthenticationService) { }

getAccountImage() {
  return this.httpService.HttpGet(APIConstants.getAccountImage, null, true);
}

getUserName() {
  const info = this.authenticationService.getUserInfo();
  return info ? info.firstName : '';
}
}
