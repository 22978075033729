<div class="modal fade" id="brandModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="createBrandModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="createBrandForm" (ngSubmit)="onSubmitBrand()" novalidate>
            <div class="form-group">
              <label class="input-title" for="name">Name</label>
              <input placeholder="Enter Brand Name" formControlName="name" type="text" class="form-control" name="name"
                required id="name" [class.is-invalid]="name.invalid && name.touched">
              <div *ngIf="name.invalid && name.touched">
                <small *ngIf="!name.value" class="text-danger">
                  Name is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="displayName">Display Name</label>
              <input placeholder="Enter Brand Display Name" formControlName="displayName" type="text"
                class="form-control" name="displayName" required id="displayName"
                [class.is-invalid]="displayName.invalid && displayName.touched">
              <div *ngIf="displayName.invalid && displayName.touched">
                <small *ngIf="!displayName.value" class="text-danger">
                  Display Name is required
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="input-title" for="description">Description</label>
              <input placeholder="Enter Brand Description" type="text" class="form-control" name="description"
                id="description" formControlName="description">
            </div>
            <div class="form-group">
              <label class="input-title" for="display">Display</label>
              <select formControlName="display" id="display" class="form-control" placeholder="Choose Display">
                <option [class.is-invalid]="display.invalid && display.touched"
                  *ngFor="let displayOption of displayOptions" value="{{displayOption.name}}">
                  {{displayOption.name}}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="input-title" for="logo">Logo Image</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Logo Image" type="file" formControlName="logo" #logoImg
                  (change)="loadLogoImage(logoImg.files)" class="form-control input-upload-file" name="logo" id="logo"
                  [class.is-invalid]="logo.invalid && logo.touched" accept="image/jpeg, image/png, image/jpg">
                <div class="preview-image__container">
                  <img class="preview-image__image" [src]="logoUrl" alt="preview of logo image" *ngIf="logoUrl">
                </div>
              </div>
              <small *ngIf="logo.touched && !logoUrl" class="text-danger">
                Logo Image is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[0]">{{errorMessages[0]}}</small>
            </div>
            <div class="form-group">
              <label class="input-title" for="image">Product List's Image</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Product List's Image" type="file" formControlName="image" #imageImg
                  (change)="loadImage(imageImg.files)" accept="image/jpeg, image/png, image/jpg"
                  class="form-control input-upload-file" name="image" id="image"
                  [class.is-invalid]="image.invalid && image.touched">
                <div class="preview-image__container">
                  <img class="preview-image__image" [src]="imageUrl" alt="preview of products's list image"
                    *ngIf="imageUrl">
                </div>
              </div>
              <small *ngIf="image.touched && !imageUrl" class="text-danger">
                Products list's Image is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[1]">{{errorMessages[1]}}</small>
            </div>
            <div class="form-group">
              <label class="input-title" for="storefrontImage">Store Image</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Store Image" type="file" formControlName="storefrontImage" #storeImg
                  (change)="loadStoreFrontImage(storeImg.files)" accept="image/jpeg, image/png, image/jpg"
                  class="form-control input-upload-file" name="storefrontImage" id="storefrontImage"
                  [class.is-invalid]="storefrontImage.invalid && storefrontImage.touched">
                <div class="preview-image__container">
                  <img class="preview-image__image" [src]="storefrontImageUrl" alt="preview of store image"
                    *ngIf="storefrontImageUrl">
                </div>
              </div>
              <small *ngIf="storefrontImage.touched && !storefrontImageUrl" class="text-danger">
                Store Image is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[2]">{{errorMessages[2]}}</small>
            </div>
            <div class="form-group">
              <label class="input-title" for="video">Video</label>
              <div class="d-flex justify-content-between align-items-center">
                <input placeholder="Choose Video" type="file" formControlName="video" #brandVideo
                  (change)="loadVideo(brandVideo.files)" accept="video/mp4, video/x-m4v, video/*"
                  class="form-control input-upload-file" name="video" id="video"
                  [class.is-invalid]="video.invalid && video.touched">
                <div class="preview-image__container">
                  <video class="preview-image__image" *ngIf="videoUrl" controls>
                    <source [src]="videoUrl">
                  </video>
                </div>
              </div>
              <small *ngIf="video.touched && !videoUrl" class="text-danger">
                Video is required.
              </small>
              <small class="text-danger" *ngIf="errorMessages[3]">{{errorMessages[3]}}</small>
            </div>
            <button
              [disabled]="loading || createBrandForm.invalid || !logoUrl || !imageUrl || !storefrontImageUrl || !videoUrl"
              class="glosho-button btn" type="submit">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{btnTitle}}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>