<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important ">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title dialog-title">{{message.title}}</h5>
        <button type="button" class="close" (click)="message.closed()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p class="dialog-text">{{message.text}}</p>
      </div>

      <div class="modal-footer">
        <button type="button" class="confirm-button btn" (click)="message.confirmed()" >{{message.confirmText}}</button>
        <button type="button" class="close-button btn" (click)="message.closed()">{{message.closeText}}</button>
      </div>
    </div>
  </div>
</div>
