export class RollingPageWindow {
    public static findPagesRollingWindow(currentPage: number, totalPages: Array<number>) {
        /*
        Method to return a rolling 5-page window around the page that
        the user is currently looking at. It is a helper method to make the
        navigation on pages easier and cleaner.
         */
        if (totalPages.length > 5) {
            const array = new Array<number>(5);
            let start;
            switch (currentPage) {
                case 1:
                case 2:
                    start = 1; break;
                case totalPages.length:
                case totalPages.length - 1:
                    start = totalPages.length - 4; break;
                default:
                    start = currentPage - 2;
            }
            for (let i = 0; i < 5; i++) {
                array[i] = start + i;
            }
            return array;
        } else {
            const array = new Array<number>(totalPages.length);
            for (let i = 0; i < totalPages.length; i++) {
                array[i] = i + 1;
            }
            return array;
        }
    }
}
