import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Output() toggleSidebar =  new EventEmitter();
  @Input() collapsed: boolean;

  constructor() { }

  ngOnInit() {
  }

  closeSidebar() {
    this.toggleSidebar.emit();
  }
}
