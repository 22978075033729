import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpService: HttpService) { }

  login(email: string, password: string) {
    const credentials = {
      email,
      password
    };
    return this.httpService.HttpPost(APIConstants.login, credentials, null);
  }

  logout(device: string) {
    const params = new HttpParams().set('device', device);
    return this.httpService.HttpPost(APIConstants.logout, params, null);
  }
}
