import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';
import { EventsList } from '../../models';
import { DateRange } from '../../models/Event';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private httpService: HttpService) { }

  getEvents(page: number, pageSize?: number, desc?: string, brand?: string, from?: Date, to?: Date) {
    let params = new HttpParams();
    params = params.set('page', String(page));
    if (pageSize) { params = params.append('pageSize', String(pageSize)); }
    if (brand) { params = params.append('brand', brand); }
    if (desc) { params = params.append('desc', desc); }
    if (from) { params = params.append('from', String(from)); }
    if (to) { params = params.append('to', String(to)); }
    return this.httpService.HttpGet<EventsList>(APIConstants.getEvents, params);
  }

  createEvent(name: string, description: string, date: DateRange, image: File,
              brand?: string, dateNote?: string, location?: string,
              additionalImages?: File[], video?: File, cblock?: string) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('dateFrom', date.from.toISOString());
    if (date.to) {formData.append('dateTo', date.to.toISOString()); }
    formData.append('image', image, image.name);
    if (brand) { formData.append('brand', brand); }
    if (cblock) { formData.append('cblock', cblock); }
    if (dateNote) { formData.append('dateNote', dateNote); }
    if (location) { formData.append('location', location); }
    if (additionalImages) {
      additionalImages.forEach(adImage => {
        formData.append('additionalImages[]', adImage, adImage.name);
      });
    }
    if (video) { formData.append('video', video, video.name); }
    return this.httpService.HttpPost<FormData>(APIConstants.createEvent, formData, null, true);
  }

  updateEvent(id: string, name: string, description: string, date: DateRange, image: File,
              brand?: string, dateNote?: string, location?: string,
              additionalImages?: File[], video?: File,
              removeFromAdditionalImages?: string[], cblock?: string) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('dateFrom', date.from.toISOString());
    if (date.to) {formData.append('dateTo', date.to.toISOString()); }
    if (image) { formData.append('image', image, image.name); }
    formData.append('brand', brand);
    formData.append('cblock', cblock);
    if (dateNote) { formData.append('dateNote', dateNote); }
    if (location) { formData.append('location', location); }
    if (additionalImages) {
      additionalImages.forEach(adImage => {
        formData.append('additionalImages[]', adImage, adImage.name);
      });
    }
    if (removeFromAdditionalImages) {
      removeFromAdditionalImages.forEach(rmImage => {
        formData.append('removeFromAdditionalImages[]', rmImage);
      });
    }
    if (video) { formData.append('video', video, video.name); }
    return this.httpService.HttpPut<FormData>(APIConstants.updateEvent(id), formData, null, true);
  }

  deleteEvent(id: string) {
    return this.httpService.HttpDelete<Event>(APIConstants.deleteEvent(id), null);
  }
}
