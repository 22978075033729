import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductService, CategoryService, BrandService, ConfirmDialogService } from '../../../services';
import { Product, ProductsList, Category, Brand } from '../../../models';
import {RollingPageWindow} from '../../../helpers';

declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {
  public findPagesRollingWindow: (currentPage: number, totalPages: Array<number>) => (number[]);
  @Output() productsListChanged = new EventEmitter();
  @Input() isSelectMode: boolean;
  @Input() showCreateProduct = new EventEmitter();
  @Input() set selectedProducts(products: Product[]) {
    this.selProducts = products;
    this.setSelectedProducts();
  }
  get selectedProducts(): Product[] {
    return this.selProducts;
  }
  private selProducts: Product[] = [];
  productsList: ProductsList;
  products: Product[];
  product: Product;
  currentPage = 1;
  totalPages = [];
  categories: Category[] = [new Category('', '', '', null, null, true)];
  brands: Brand[] = [new Brand('', '', '', '', null, null, null, null, true)];
  constructor(private productService: ProductService,
              private categoryService: CategoryService,
              private brandService: BrandService,
              private confirmDialogService: ConfirmDialogService
  ) {

    this.findPagesRollingWindow = RollingPageWindow.findPagesRollingWindow;
  }

  ngOnInit() {
    this.getProducts(this.currentPage);
    this.getCategories();
    this.getBrands();
    this.catchShowCreateProduct();
  }

  catchShowCreateProduct() {
    this.showCreateProduct.subscribe(() => {
      this.product = null;
      $('#productModal').modal('show');
    });
  }

  getProducts(page: number, brand?: string, category?: string, desc?: string): any {
    this.products = [];
    this.totalPages = [];
    this.productService.getProducts(page, 10, brand, category, desc)
      .subscribe(
      value => {
        this.productsList = value;
        for (let i = 1; i <= value.totalPages; i++) {
          this.totalPages.push(i);
        }
        this.products = value.products;
        if (this.isSelectMode) { this.setSelectedProducts(); }
      }
    );
  }

  setSelectedProducts() {
    if (!this.products || !this.selectedProducts) { return; }
    this.products.forEach(product => {
      const exists = this.selectedProducts.find((element) => {
        return product._id === element._id;
      });
      product.selected = !!exists;
    });
  }

  getCategories() {
    this.categoryService.getCategories(1, 100).subscribe(
      value => {
        this.categories = this.categories.concat(value.categories);
      }
    );
  }

  getBrands() {
    this.brandService.getBrands().subscribe((value) => {
      this.brands = this.brands.concat(value.brands);
    });
  }

  filterProducts($event, page, brand, category, desc) {
    console.log(page, category + brand + desc);
    this.getProducts(page, brand, category, desc);
  }

  selectProduct(product) {
    product.selected = !product.selected;
    this.selectedProductsChanged(product);
  }

  selectedProductsChanged(product) {
    if (product.selected) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts = this.selectedProducts.filter((pr) => {
        return pr._id !== product._id;
      });
    }
    this.productsListChanged.emit(this.selectedProducts);
  }

  showEditProduct(product: Product) {
    this.product = product;
    $('#productModal').modal('show');
  }

  showConfirmDialog(id: string) {
    this.confirmDialogService.confirmThis('Confirm', 'Are you sure that you want to delete this product?', 'Yes', 'No',
      () => {
        this.deleteProduct(id);
      }, () => {
      });
  }

  deleteProduct(id: string) {
    this.productService.deleteProduct(id).subscribe(
      value => {
        this.products = this.products.filter((product) => {
          return product._id !== id;
        });
      }
    );
  }

  activateDeactivateProduct(product) {
    const isActivated = !product.isActivated;
    this.productService.activateDeactivateProduct(product._id, isActivated).subscribe(
        value => {
          product.isActivated = isActivated;
        }
    );
  }
}
