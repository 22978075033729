<div class="container-fluid">
  <form [formGroup]="createContentBlockForm" (ngSubmit)="onSubmitContentBlock()" novalidate>
    <div class="form-group">
      <label class="input-title" for="title">Title</label>
      <input placeholder="Enter Content Block Title" formControlName="title" type="text" class="form-control"
        name="title" required id="title" [class.is-invalid]="title.invalid && title.touched">
      <div *ngIf="title.invalid && title.touched">
        <small *ngIf="!title.value" class="text-danger">
          Title is required
        </small>
      </div>
    </div>
    <div class="form-group">
      <label class="input-title" for="subTitle">SubTitle</label>
      <input placeholder="Enter Content Block Subtitle" formControlName="subTitle" type="text" class="form-control"
        name="subTitle" required id="subTitle" [class.is-invalid]="subTitle.invalid && subTitle.touched">
      <div *ngIf="subTitle.invalid && subTitle.touched">
        <small *ngIf="!subTitle.value" class="text-danger">
          Subtitle is required
        </small>
      </div>
    </div>
    <div class="form-group">
      <label class="input-title" for="contenBlockType">Type</label>
      <select formControlName="contenBlockType" required id="contenBlockType" class="form-control"
        placeholder="Choose Type">
        <option [class.is-invalid]="contenBlockType.invalid && contenBlockType.touched" *ngFor="let type of types"
          value="{{type._id}}">
          {{type.code}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="input-title" for="image">Image</label>
      <div class="d-flex justify-content-between align-items-center">
        <input placeholder="Choose Image" type="file" formControlName="image" #img (change)="loadImage(img.files)"
          class="form-control input-upload-file" name="image" id="image"
          [class.is-invalid]="image.invalid && image.touched" accept="image/jpeg, image/png, image/jpg">
        <div class="preview-image__container">
          <img class="preview-image__image" [src]="imageUrl" alt="preview of image" *ngIf="imageUrl">
        </div>
      </div>
      <small *ngIf="image.touched && !imageUrl" class="text-danger">
        Image is required.
      </small>
      <small class="text-danger" *ngIf="errorMessage">{{errorMessage}}</small>
    </div>
    <div class="form-group">
      <label class="input-title" for="productLimit">Product Limit</label>
      <input placeholder="Enter Product Limit" type="text" pattern="(-1|[1-9]*)" class="form-control" id="productLimit"
        name="productLimit" formControlName="productLimit">
      <div *ngIf="productLimit.invalid && productLimit.touched">
        <small class="text-danger">
          Accepts only positive integers or -1.
        </small>
      </div>
    </div>
    <div class="form-group">
      <label class="input-title" for="displayOrder">Display Order</label>
      <input placeholder="Enter Display Order" type="text" pattern="[0-9]*" class="form-control" id="displayOrder"
        name="displayOrder" formControlName="displayOrder">
      <div *ngIf="displayOrder.invalid && displayOrder.touched">
        <small class="text-danger">
          Accepts only integers.
        </small>
      </div>
    </div>

    <section class="carousel slide" data-ride="carousel" data-interval="false" id="postsCarousel">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-outline-secondary float-left" (click)="showProductsList()">
              <fa-icon icon="plus"></fa-icon> Products
            </button>
            <button type="button" class="btn btn-outline-secondary float-right"
              [ngClass]="{'disabled': totalProductsRows.length == 1}" (click)="showNextProducts()">
              <fa-icon icon="chevron-right"></fa-icon>
            </button>
            <button type="button" class="btn btn-outline-secondary float-right mr-2"
              [ngClass]="{'disabled': totalProductsRows.length == 1}" (click)="showPrevProducts()">
              <fa-icon icon="chevron-left"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid carousel-inner pt-0">
        <div *ngFor="let row of totalProductsRows; let i = index" class="row row-equal carousel-item m-0"
          [ngClass]="{'active': i == currentProductRow}">
          <div class="col-md-4" *ngFor="let product of selectedProducts | slice:3*i:(3*i)+3; let i2 = index"
            [ngClass]="{'pl-lg-0': i2 == 0, 'pr-lg-0': i2 == 2}">
            <div class="card">
              <a class="text-right pr-1 cursor" (click)="deleteSelectedProduct(product._id)">
                <fa-icon icon="times"></fa-icon>
              </a>
              <div class="card-img-top card-img-top-200">
                <img class="img-fluid" src="{{product.image}}" alt="...">
              </div>
              <div class="card-block p-t-2">
                <h2 class="card-product-title p-2">{{product.brand.name}}</h2>
                <h4 class="card-product-subTitle pl-2 pt-0 pr-2 pb-2">
                  {{product.name}}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <button [disabled]="loading || createContentBlockForm.invalid || selectedProducts.length == 0 || !imageUrl"
      class="glosho-button btn" type="submit">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{btnTitle}}
    </button>
  </form>
</div>

<app-modal-products-list [selectedProducts]="initialSelectedProducts"
  (productsListChanged)="getSelectedProductsList($event)">
</app-modal-products-list>