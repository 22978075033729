import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../HttpService/Http.service';
import { APIConstants } from '../../helpers';
import { ProductsList, Product } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpService: HttpService) { }

  getProducts(page: number, pageSize?: number, brand?: string, category?: string, desc?: string) {
    let params = new HttpParams().set('page', String(page));
    if (pageSize) { params = params.append('pageSize', String(pageSize)); }
    if (brand) { params = params.append('brand', brand); }
    if (category) { params = params.append('category', category); }
    if (desc) { params = params.append('desc', desc); }
    return this.httpService.HttpGet<ProductsList>(APIConstants.getProducts, params);
  }

  deleteProduct(id: string) {
    return this.httpService.HttpDelete<Product>(APIConstants.getProduct(id), null);
  }

  createProduct(name: string, description: string, image: File, category: string, brand: string,
                additionalImages?: File[], shortDescription?: string, productNo?: string, rewardPoints?: number,
                order?: number, price?: any, review?: any) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('image', image, image.name);
    formData.append('category', category);
    formData.append('brand', brand);
    if (additionalImages) {
      additionalImages.forEach(adImage => {
        formData.append('additionalImages[]', adImage);
      });
    }
    if (shortDescription) { formData.append('shortDescription', shortDescription); }
    if (productNo) { formData.append('productNo', productNo); }
    if (price) {
      formData.append('priceFixed', price.fixed);
      formData.append('bestPriceGuarantee', price.bestPriceGuarantee);
    }
    if (review) {
      formData.append('reviewStars', review.stars);
      formData.append('reviewerName', review.reviewerName);
      formData.append('reviewText', review.text);
    }
    if (rewardPoints && rewardPoints >= 0) { formData.append('rewardPoints', String(rewardPoints)); }
    if (order && order >= 0) { formData.append('order', String(order)); }
    return this.httpService.HttpPost<FormData>(APIConstants.getProducts, formData, null, true);
  }

  updateProduct(id: string, name: string, description: string, image: File, category: string, brand: string,
                additionalImages?: File[], shortDescription?: string, productNo?: string, rewardPoints?: number,
                order?: number, price?: any, review?: any, removeFromAdditionalImages?: string[]) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (image) { formData.append('image', image, image.name); }
    formData.append('category', category);
    formData.append('brand', brand);
    if (additionalImages) {
      additionalImages.forEach(adImage => {
        formData.append('additionalImages[]', adImage);
      });
    }
    if (removeFromAdditionalImages) {
      removeFromAdditionalImages.forEach(rmImage => {
        formData.append('removeFromAdditionalImages[]', rmImage);
      });
    }
    formData.append('shortDescription', shortDescription);
    formData.append('productNo', productNo);
    if (price) {
      formData.append('priceFixed', price.fixed);
      formData.append('bestPriceGuarantee', price.bestPriceGuarantee);
    }
    if (review) {
      formData.append('reviewStars', review.stars);
      formData.append('reviewerName', review.reviewerName);
      formData.append('reviewText', review.text);
    }
    formData.append('rewardPoints', String(rewardPoints));
    formData.append('order', String(order));
    return this.httpService.HttpPut<FormData>(APIConstants.getProduct(id), formData, null, true);
  }

  activateDeactivateProduct(id: string, isActivated: boolean) {
      if (isActivated === true) {
        return this.httpService.HttpPost(APIConstants.activateProduct(id), null, null);
      }
      return this.httpService.HttpPost(APIConstants.deactivateProduct(id), null, null);
    }
}
