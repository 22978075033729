export class Store {
  constructor(
    // tslint:disable-next-line:variable-name
    public _id: string,
    public name: string,
    public streetAddress: string,
    public additionalStreetAddress: string,
    public postcode: string,
    public city: string,
    public country: string,
    public location: number[],
    public phone: string,
    public hours: string,
    public brands: StoreBrands[],
    public isActivated: boolean,
    public selected?: boolean
  ) {}
}

export class StoresList {
  constructor(
    public stores: Store[],
    public totalDocs: number,
    public pageSize: number,
    public page: number,
    public totalPages: number,
    public pagingCounter: number,
    public hasPrevPage: boolean,
    public hasNextPage: boolean,
    public prevPage: boolean,
    public nextPage: boolean
  ) {}
}

export class StoreBrands {
  constructor(
    // tslint:disable-next-line:variable-name
    public _id: string,
    public name: string,
    public logo: any
  ) {}
}
