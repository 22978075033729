import {Component, OnInit} from '@angular/core';
import { User, UsersList } from '../../../models/User';
import { UserService } from '../../../services';
import {GlobalVariables, RollingPageWindow} from '../../../helpers';

declare const $;

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    usersList: UsersList;
    users: User[];
    user: User;
    roles: string[] = [undefined, 'USER', 'ADMIN'];
    currentPage = 1;
    totalPages: number[];

    public findPagesRollingWindow: (currentPage: number, totalPages: Array<number>) => (number[]);

    constructor(private userService: UserService,
                private globalVariables: GlobalVariables) {

        this.findPagesRollingWindow = RollingPageWindow.findPagesRollingWindow;
    }

    ngOnInit() {
        this.globalVariables.navBarTitle = 'Users';
        this.getUsers(this.currentPage);
    }

    getUsers(page: number, desc?: string, role?: string): any {
        this.userService.getUsers(page, 10, desc, role)
            .subscribe((value) => {
                this.usersList = value;
                this.totalPages = new Array<number>(value.totalPages);
                this.users = value.users;
            });
    }

    filterUsers($event, page, desc, role) {
        this.getUsers(page, desc, role);
    }
}
